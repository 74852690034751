import React, { Component } from 'react';
import { Header , Form, Button, Table, Divider, Icon } from 'semantic-ui-react'
import '../stylesheets/RegisterForm.css';
import $ from 'jquery';



export default class RegisterForm extends Component {
  constructor(props){
    super(...arguments);
    /*this.data = extend([], scheduleData, null, true);*/

    this.state = {
      //testValue:'failed test api',
      formType:'login',
      //login 
      usernameLogin:'',
      passwordLogin:'',
      //signup
      UserName: '',
      FirstName: '',
      LastName: '',
      Password: '',
      PasswordRe: '',
      ProfilePicture: null,
      Email: '',
      Address: '',
      Phone: '',

      access_token:''
    }
  }

  componentDidMount(){
    //this.handleTest();
  }
  
  /*navTo = (uri) =>{
    window.location.href = window.location.origin + uri;
  }*/

  //Logins

  handleUsernameLogin = (event) => {
      this.setState({usernameLogin:event.target.value})
  }

  handlePasswordLogin = (event) => {
    this.setState({passwordLogin: event.target.value})
  }

  handleLogin = () => {
    $.ajax({
      url: `/api/logins`, //TODO: update request URL
      type: "POST",
      dataType: 'json',
      contentType: 'application/json',
      data: JSON.stringify({
        username: this.state.usernameLogin,
        password: this.state.passwordLogin
      }),
      xhrFields: {
        withCredentials: true
      },
      crossDomain: true,
      success: (result) => {
        if(result?.active){
          alert(result?.active)
        } else{
          sessionStorage.setItem("access_token",result.access_token);
          sessionStorage.setItem("refresh_token",result.refresh_token);
          this.props.setToken(result.access_token);
          this.props.history.push('/');
          /*this.setState({access_token:result.access_token}, async () =>{
              this.props.history.push('/');
          });*/
          
        }
        return;
      },
      error: (error) => {
        if(this.state.usernameLogin==='activate'){
          alert('activation key error!')
        }else{
          alert('Unable to Login. Please check your username / password')
        }
        return;
      }
    })    
  }

  /*handleTest = () => {
    $.ajax({
      url: `/api/tests`, //TODO: update request URL
      type: "GET",
      xhrFields: {
        withCredentials: true
      },
      crossDomain: true,
      success: (result) => {
        this.setState({
          testValue: result.test
        })
        return;
      },
      error: (error) => {
        //alert('Unable to load the schedule of the chosen room. Please try your request again')
        return;
      }
    })    
  }*/

    
  
  render() {
    //let url = "/"
    
    return (
      <>
          <div id = "signIn">
            <Form success>    
                <Form.Input
                  fluid
                  label='Username / Email'
                  placeholder='Username / Email'
                  onChange={this.handleUsernameLogin}
                />

                <Form.Input
                  fluid
                  label='Password'
                  type='password'
                  placeholder='Please enter password'
                  onChange={this.handlePasswordLogin}
                />
                <Button id='signinsubmit' onClick={this.handleLogin}>
                  Sign-in
                </Button>
            </Form>
          </div>          
    </>
    )
  }
}
