import React from "react";
import {
  Category,
  ChartComponent,
  ColumnSeries,
  Inject,
  Legend,
  SeriesCollectionDirective,
  SeriesDirective,
  Tooltip,
  DataLabel,
} from "@syncfusion/ej2-react-charts";

const Chart = ({ Summary }) => {
  const primaryxAxis = { valueType: "Category" };
  const marker = {
    visible: true,
    height: 10,
    width: 10,
    dataLabel: { visible: true },
  };

  // Transforming Summary data into a format suitable for the chart
  const dataSource = [
    {
      category: "Active Subscriptions",
      count: Summary.students_with_active_subscription,
    },
    {
      category: "Expired Subscriptions",
      count: Summary.students_with_all_expired_subscriptions,
    },
    {
      category: "No Subscriptions",
      count: Summary.students_with_no_subscriptions,
    },
    {
      category: "Total Number of Students",
      count: Summary.total_students,
    },
  ];

  return (
    <ChartComponent
      id={"activeStudentsChart"}
      primaryXAxis={primaryxAxis}
      dataSource={dataSource}
      title={"Students Chart"}
    >
      <Inject services={[ColumnSeries, Legend, Category, Tooltip, DataLabel]} />
      <SeriesCollectionDirective>
        <SeriesDirective
          xName="category"
          yName="count"
          name="Student Count"
          type="Column"
          fill="orange"
          marker={marker}
        />
      </SeriesCollectionDirective>
    </ChartComponent>
  );
};

export default Chart;
