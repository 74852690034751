//import {format} from 'date-fns'
import { Button , Icon} from 'semantic-ui-react'

var navTo = (uri) =>{
    window.location.href = window.location.origin + uri;
}

export const COLUMNS = [
    {
        Header:()=>'',
        accessor:'id',
        Cell:(props) => (
            <Button onClick={()=>{
                console.log(props.row.original);
                props.setStudentToRemove(props.row.original)
            }}> <Icon name='delete'/> Remove from Attendance</Button>
        ),
        disableFilters: true
    },
    {
        Header:()=>'Edit Student',
        accessor:'id2',
        Cell:(props) => (
            <Button circular icon='edit' onClick={()=>{
                // console.log(props.row.original);
                props.history.push(`/students/${props.row.values.id}`)
            }} />
        ),
        disableFilters: true
    },
    {
        Width:10,
        Header: 'ID',
        accessor: 'user_id'
    },
    {
        Header: 'Student Name',
        accessor: 'full_name'
    },
    {
        Header: 'Due Amount (EGP)',
        accessor:'due_amount' 
    }
]