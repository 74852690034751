import { format, formatDistance, formatRelative, subDays } from 'date-fns'
// import { Button } from 'semantic-ui-react'
// import { Link } from 'react-router-dom'


export const COLUMNS = [
    {
        Header:'#',
        accessor:'index',
        disableFilters: true
    },
    {
        Header:'Student Name',
        accessor:'name',
        disableFilters: true
    },
    {
        Header: 'Instructor Share',
        accessor: 'instructor_share',
        disableFilters: true
    }
]