import React from "react";
import { useAsyncDebounce } from "react-table";
import { Dropdown, Input } from "semantic-ui-react";
import "./table.scss";

export const ColumnFilter = ({ column }) => {
  const { filterValue, setFilter } = column;

  // Function to debounce changes to the filter
  const onChange = useAsyncDebounce((value) => {
    setFilter(value || undefined);
  }, 1000);

  // Function to handle sorting
  const handleSort = (column, type) => {
    if (type === "as") {
      column.toggleSortBy(false, false); // sort in ascending order
    } else if (type === "ds") {
      column.toggleSortBy(true, false); // sort in descending order
    } else {
      column.clearSortBy(); // clear sorting
    }
  };

  return (
    <div id="basicTableFilterContainer">
      <Dropdown
        icon="filter"
        floating
        className="icon"
        closeOnBlur={false}
        closeOnChange={false}
        closeOnEscape={false}
      >
        <Dropdown.Menu>
          <Dropdown.Item onClick={(e) => e.stopPropagation()}>
            <Input
              icon="search"
              iconPosition="left"
              className="search"
              onChange={(e) => {
                setFilter(e.target.value);
                onChange(e.target.value);
              }}
              id="basicTableSearchInput"
            />
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item
            onClick={(e) => {
              e.stopPropagation();
              handleSort(column, "as");
            }}
            id="basicTableSortAsc"
          >
            Sort ascending
          </Dropdown.Item>
          <Dropdown.Item
            onClick={(e) => {
              e.stopPropagation();
              handleSort(column, "ds");
            }}
            id="basicTableSortDesc"
          >
            Sort descending
          </Dropdown.Item>
          <Dropdown.Item
            onClick={(e) => {
              e.stopPropagation();
              handleSort(column, "n");
            }}
            id="basicTableSortNone"
          >
            Cancel sort
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
