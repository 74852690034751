import React, { useEffect, useState } from "react";
import {
  ChartComponent,
  SeriesCollectionDirective,
  SeriesDirective,
  Inject,
  Legend,
  Category,
  Tooltip,
  ColumnSeries,
} from "@syncfusion/ej2-react-charts";

const Chart = ({ data, year }) => {
  // const marker = {
  //   visible: true,
  //   height: 10,
  //   width: 10,
  //   dataLabel: {
  //     visible: true,
  //   },
  // };

  // console.log(data);

  return (
    <ChartComponent
      id={"subscriptionsChart"}
      primaryXAxis={{ valueType: "Category" }}
      title={"Monthly Subscriptions per Dance Category Y" + year}
      tooltip={{ enable: true }}
    >
      <Inject services={[ColumnSeries, Legend, Tooltip, Category]} />
      <SeriesCollectionDirective>
        {data.map((item, index) => (
          <SeriesDirective
            key={index}
            dataSource={item.data}
            xName="month"
            yName="subscriptions"
            name={item.category}
            type="Column"
            // marker={marker}
          />
        ))}
      </SeriesCollectionDirective>
    </ChartComponent>
  );
};

export default Chart;
