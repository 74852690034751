import React from 'react'
import { Menu } from 'semantic-ui-react'
import '../../stylesheets/Header.css';
import { useHistory } from 'react-router-dom';
import $ from 'jquery';



export const LogoutButton = (props) =>{
    /*const navTo = (uri) =>{
        window.location.href = window.location.origin + uri;
      }*/
    let history = useHistory();
    let logout = ()=> {
        $.ajax({
            url: `/api/login-tracks`, //TODO: update request URL
            type: "POST",
            beforeSend: function (xhr) {   //Include the bearer token in header
              xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
            },
            dataType: 'json',
            contentType: 'application/json',
            data: JSON.stringify({
                action: 'Logged out'
            }),
            xhrFields: {
              withCredentials: true
            },
            crossDomain: true,
            success: (result) => {
                return;
            },
            error: (error) => {
                return;
            }
        });
        sessionStorage.removeItem("access_token")
        props.setToken(null)
        history.push('/');
    }
    return(
        <>
            <Menu.Item
            className='menu_item'
            name='Logout'
            position='right'
            onClick={()=>logout()}
            >
                sign out
            </Menu.Item>
        </>
    )
}