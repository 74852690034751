import React, { useState, useEffect } from "react";
import { Table, Icon, Dropdown, Divider, Button } from "semantic-ui-react";

import reportsImage from "../../media/Images/reports.jpg";
import Chart from "./Chart";
import $ from "jquery";
import styles from "./SubscriptionCategoryReport.module.scss";
import { de } from "date-fns/locale";

const DateFilter = ({ year, setYear, view }) => {
  const years = Array.from(
    new Array(10),
    (val, index) => new Date().getFullYear() - index
  );
  const yearOptions = years.map((year) => ({
    key: year,
    text: year,
    value: year,
  }));

  return (
    <div id="payment">
      <Divider horizontal>
        <h4>
          <Icon name="filter" />
          Filter
        </h4>
      </Divider>
      <Table definition>
        <Table.Body>
          <Table.Row>
            <Table.Cell width={2}>Year</Table.Cell>
            <Table.Cell>
              <Dropdown
                placeholder="Select Year"
                fluid
                selection
                options={yearOptions}
                value={year}
                onChange={(e, { value }) => setYear(value)}
              />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <Button
        id="submit_view_subs_payments"
        floated="right"
        size="mini"
        color="red"
        onClick={view}
      >
        {" "}
        View Subscriptions{" "}
      </Button>

      <Divider horizontal />
    </div>
  );
};

export default DateFilter;
