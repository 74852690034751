import React, { useState } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { isExpired } from "react-jwt"; //, decodeToken
//import {Loading} from './components/loading'
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import $ from "jquery";
import "./stylesheets/App.css";

//import './stylesheets/App.css';
import LoginRegisteration from "./components/LoginRegisteration";
import Home from "./components/Home/Home";
import Schedule from "./components/Schedule";
import ScheduleCopy from "./components/ScheduleCopy";
import FullScheduleInstructor from "./components/InstructorPrivilege/FullScheduleInstructor";
import MyScheduleInstructor from "./components/InstructorPrivilege/MyScheduleInstructor";
import FullScheduleStudent from "./components/StudentPrivilege/FullScheduleStudent";
import MyScheduleStudent from "./components/StudentPrivilege/MyScheduleStudent";
import FullSchedulePublic from "./components/PublicPrivilege/FullSchedulePublic";
import { Header } from "./components/Headers/Header";
import Room from "./components/Room/Room";
import RoomEdit from "./components/Room/RoomEdit";
import Category from "./components/Dance_Category/Category";
import CategoryEdit from "./components/Dance_Category/CategoryEdit";
import Dance from "./components/Dance/Dance";
import DanceEdit from "./components/Dance/DanceEdit";
import Level from "./components/Level/Level";
import LevelEdit from "./components/Level/LevelEdit";
import Package from "./components/Package/Package";
import PackageEdit from "./components/Package/PackageEdit";
import Membership from "./components/membership/Membership";
import MembershipEdit from "./components/membership/MembershipEdit";

import Partner from "./components/Partner/Partner";
import PartnerEdit from "./components/Partner/PartnerEdit";
import Admin from "./components/Admin/Admin";
import AdminEdit from "./components/Admin/AdminEdit";
import Employee from "./components/Employee/Employee";
import EmployeeEdit from "./components/Employee/EmployeeEdit";
import Instructor from "./components/Instructor/Instructor";
import InstructorEdit from "./components/Instructor/InstructorEdit";
import Student from "./components/Student/Student";
import StudentEdit from "./components/Student/StudentEdit";

import AdminAP from "./components/AdminPrivilege/AdminAP/AdminAP";
import InstructorAP from "./components/AdminPrivilege/InstructorAP/InstructorAP";
import PartnerAP from "./components/AdminPrivilege/PartnerAP/PartnerAP";

import StudentSubscriptionPriv from "./components/StudentPrivilege/StudentSubscription/StudentSubscriptionPriv";
import Subscription from "./components/Subscription/Subscription";
import Misc from "./components/Misc/Misc";
import MiscEdit from "./components/Misc/MiscEdit";
import Kitchen from "./components/Kitchen/Kitchen";
import KitchenEdit from "./components/Kitchen/KitchenEdit";
import Leave from "./components/Leave/Leave";
import LeaveEdit from "./components/LeaveEdit/LeaveEdit";
import Track from "./components/Track/Track";
import LogTrack from "./components/LogTrack/LogTrack";
import ClassInfo from "./components/ClassInfo/ClassInfo";
import ClassInfoInstPriv from "./components/InstructorPrivilege/ClassInfo_InstPriv/ClassInfoInstPriv";
import ClassInfoStudPriv from "./components/StudentPrivilege/ClassInfo_StudPriv/ClassInfoStudPriv";
import ClassInfoPublic from "./components/PublicPrivilege/ClassInfo_Public/ClassInfoPublic";
import InstructorAdd from "./components/ClassInfo/InstructorAdd/InstructorAdd";
import StudentAdd from "./components/ClassInfo/StudentAdd/StudentAdd";
import AttendanceInfo from "./components/AttendanceInfo/AttendanceInfo";
import UserEdit from "./components/User/UserEdit";
import Payment from "./components/Payment/Payment";
import Closing from "./components/Closing/Closing";
import SalaryPayslip from "./components/SalaryPayslip/SalaryPayslip";
import StudentReports from "./components/StudentReports";
import SubscriptionCategoryReport from "./components/SubscriptionCategoryReport";
import SubscriptionMonthlyReport from "./components/SubscriptionMonthlyReport";

const App = () => {
  let [token, setToken] = useState(sessionStorage.getItem("access_token"));
  let history = useHistory();
  let [aboutUs, setAboutUs] = useState(false);
  let [contact, setContact] = useState(false);
  let [prevAuth, setPrevAuth] = useState(0);

  function refreshToken() {
    if (isExpired(sessionStorage.getItem("access_token"))) {
      $.ajax({
        url: `/api/refresh`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {
          //Include the bearer token in header
          xhr.setRequestHeader(
            "Authorization",
            "Bearer " + sessionStorage.getItem("refresh_token")
          );
        },
        dataType: "json",
        contentType: "application/json",
        data: JSON.stringify({}),
        xhrFields: {
          withCredentials: true,
        },
        crossDomain: true,
        success: (result) => {
          sessionStorage.setItem("access_token", result.access_token);
          return;
        },
        error: (error) => {
          sessionStorage.removeItem("access_token");
          sessionStorage.removeItem("refresh_token");
          setToken(null);
          history.push("/logins");
          return;
        },
      });
    }
  }

  return (
    <div className="App">
      <Header
        id="header"
        token={token}
        setToken={setToken.bind(this)}
        history={history}
        aboutUs={aboutUs}
        setAboutUs={setAboutUs}
        contact={contact}
        setContact={setContact}
        path
      />
      <Switch>
        <ProtectedRoute
          path="/rooms"
          exact
          component={Room}
          prevAuth={prevAuth}
          requiredAuth={3}
          refreshToken={refreshToken.bind(this)}
          history={history}
        />
        <ProtectedRoute
          path="/rooms/:id"
          exact
          component={RoomEdit}
          prevAuth={prevAuth}
          requiredAuth={3}
          refreshToken={refreshToken.bind(this)}
          history={history}
        />
        <ProtectedRoute
          path="/categories"
          exact
          component={Category}
          prevAuth={prevAuth}
          requiredAuth={3}
          refreshToken={refreshToken.bind(this)}
          history={history}
        />
        <ProtectedRoute
          path="/categories/:id"
          exact
          component={CategoryEdit}
          prevAuth={prevAuth}
          requiredAuth={3}
          refreshToken={refreshToken.bind(this)}
          history={history}
        />
        <ProtectedRoute
          path="/dances"
          exact
          component={Dance}
          prevAuth={prevAuth}
          requiredAuth={3}
          refreshToken={refreshToken.bind(this)}
          history={history}
        />
        <ProtectedRoute
          path="/dances/:id"
          exact
          component={DanceEdit}
          prevAuth={prevAuth}
          requiredAuth={3}
          refreshToken={refreshToken.bind(this)}
          history={history}
        />
        <ProtectedRoute
          path="/levels"
          exact
          component={Level}
          prevAuth={prevAuth}
          requiredAuth={3}
          refreshToken={refreshToken.bind(this)}
          history={history}
        />
        <ProtectedRoute
          path="/levels/:id"
          exact
          component={LevelEdit}
          prevAuth={prevAuth}
          requiredAuth={3}
          refreshToken={refreshToken.bind(this)}
          history={history}
        />
        <ProtectedRoute
          path="/packages"
          exact
          component={Package}
          prevAuth={prevAuth}
          requiredAuth={3}
          refreshToken={refreshToken.bind(this)}
          history={history}
        />
        <ProtectedRoute
          path="/packages/:id"
          exact
          component={PackageEdit}
          prevAuth={prevAuth}
          requiredAuth={3}
          refreshToken={refreshToken.bind(this)}
          history={history}
        />
        <ProtectedRoute
          path="/memberships"
          exact
          component={Membership}
          prevAuth={prevAuth}
          requiredAuth={3}
          refreshToken={refreshToken.bind(this)}
          history={history}
        />
        <ProtectedRoute
          path="/memberships/:id"
          exact
          component={MembershipEdit}
          prevAuth={prevAuth}
          requiredAuth={3}
          refreshToken={refreshToken.bind(this)}
          history={history}
        />
        <ProtectedRoute
          path="/subscriptions"
          exact
          component={Subscription}
          prevAuth={prevAuth}
          requiredAuth={3}
          refreshToken={refreshToken.bind(this)}
          history={history}
        />
        <ProtectedRoute
          path="/miscs"
          exact
          component={Misc}
          prevAuth={prevAuth}
          requiredAuth={3}
          refreshToken={refreshToken.bind(this)}
          history={history}
        />
        <ProtectedRoute
          path="/miscs/:id"
          exact
          component={MiscEdit}
          prevAuth={prevAuth}
          requiredAuth={3}
          refreshToken={refreshToken.bind(this)}
          history={history}
        />
        <ProtectedRoute
          path="/kitchens"
          exact
          component={Kitchen}
          prevAuth={prevAuth}
          requiredAuth={3}
          refreshToken={refreshToken.bind(this)}
          history={history}
        />
        <ProtectedRoute
          path="/kitchens/:id"
          exact
          component={KitchenEdit}
          prevAuth={prevAuth}
          requiredAuth={3}
          refreshToken={refreshToken.bind(this)}
          history={history}
        />
        <ProtectedRoute
          path="/leaves"
          exact
          component={Leave}
          prevAuth={prevAuth}
          requiredAuth={4}
          refreshToken={refreshToken.bind(this)}
          history={history}
        />
        <ProtectedRoute
          path="/leaves/:id"
          exact
          component={LeaveEdit}
          prevAuth={prevAuth}
          requiredAuth={4}
          refreshToken={refreshToken.bind(this)}
          history={history}
        />
        <ProtectedRoute
          path="/tracks"
          exact
          component={Track}
          prevAuth={prevAuth}
          requiredAuth={4}
          refreshToken={refreshToken.bind(this)}
          history={history}
        />
        <ProtectedRoute
          path="/login-tracks"
          exact
          component={LogTrack}
          prevAuth={prevAuth}
          requiredAuth={4}
          refreshToken={refreshToken.bind(this)}
          history={history}
        />

        <ProtectedRoute
          path="/schedules"
          exact
          component={Schedule}
          prevAuth={prevAuth}
          requiredAuth={3}
          refreshToken={refreshToken.bind(this)}
          history={history}
        />
        <ProtectedRoute
          path="/requests-schedule"
          exact
          component={ScheduleCopy}
          prevAuth={prevAuth}
          requiredAuth={3}
          refreshToken={refreshToken.bind(this)}
          history={history}
        />

        <ProtectedRoute
          path="/instructors/:id/fullschedules"
          exact
          component={FullScheduleInstructor}
          prevAuth={prevAuth}
          requiredAuth={2}
          refreshToken={refreshToken.bind(this)}
          history={history}
        />
        <ProtectedRoute
          path="/instructors/:id/schedules"
          exact
          component={MyScheduleInstructor}
          prevAuth={prevAuth}
          requiredAuth={2}
          refreshToken={refreshToken.bind(this)}
          history={history}
        />

        <ProtectedRoute
          path="/students/:id/fullschedules"
          exact
          component={FullScheduleStudent}
          prevAuth={prevAuth}
          requiredAuth={1}
          refreshToken={refreshToken.bind(this)}
          history={history}
        />
        <ProtectedRoute
          path="/students/:id/schedules"
          exact
          component={MyScheduleStudent}
          prevAuth={prevAuth}
          requiredAuth={1}
          refreshToken={refreshToken.bind(this)}
          history={history}
        />

        <Route path="/public-schedules" exact component={FullSchedulePublic} />

        <ProtectedRoute
          path="/partners"
          exact
          component={Partner}
          prevAuth={prevAuth}
          requiredAuth={4}
          refreshToken={refreshToken.bind(this)}
          history={history}
        />
        <ProtectedRoute
          path="/partners/:id"
          exact
          component={PartnerEdit}
          prevAuth={prevAuth}
          requiredAuth={4}
          refreshToken={refreshToken.bind(this)}
          history={history}
        />
        <ProtectedRoute
          path="/admins"
          exact
          component={Admin}
          prevAuth={prevAuth}
          requiredAuth={4}
          refreshToken={refreshToken.bind(this)}
          history={history}
        />
        <ProtectedRoute
          path="/admins/:id"
          exact
          component={AdminEdit}
          prevAuth={prevAuth}
          requiredAuth={4}
          refreshToken={refreshToken.bind(this)}
          history={history}
        />
        <ProtectedRoute
          path="/employees"
          exact
          component={Employee}
          prevAuth={prevAuth}
          requiredAuth={4}
          refreshToken={refreshToken.bind(this)}
          history={history}
        />
        <ProtectedRoute
          path="/employees/:id"
          exact
          component={EmployeeEdit}
          prevAuth={prevAuth}
          requiredAuth={4}
          refreshToken={refreshToken.bind(this)}
          history={history}
        />
        <ProtectedRoute
          path="/instructors"
          exact
          component={Instructor}
          prevAuth={prevAuth}
          requiredAuth={4}
          refreshToken={refreshToken.bind(this)}
          history={history}
        />
        <ProtectedRoute
          path="/instructors/:id"
          exact
          component={InstructorEdit}
          prevAuth={prevAuth}
          requiredAuth={4}
          refreshToken={refreshToken.bind(this)}
          history={history}
        />

        <ProtectedRoute
          path="/partners-ap"
          exact
          component={PartnerAP}
          prevAuth={prevAuth}
          requiredAuth={3}
          refreshToken={refreshToken.bind(this)}
          history={history}
        />
        <ProtectedRoute
          path="/admins-ap"
          exact
          component={AdminAP}
          prevAuth={prevAuth}
          requiredAuth={3}
          refreshToken={refreshToken.bind(this)}
          history={history}
        />
        <ProtectedRoute
          path="/instructors-ap"
          exact
          component={InstructorAP}
          prevAuth={prevAuth}
          requiredAuth={3}
          refreshToken={refreshToken.bind(this)}
          history={history}
        />
        <ProtectedRoute
          path="/students"
          exact
          component={Student}
          prevAuth={prevAuth}
          requiredAuth={3}
          refreshToken={refreshToken.bind(this)}
          history={history}
        />
        <ProtectedRoute
          path="/students/:id"
          exact
          component={StudentEdit}
          prevAuth={prevAuth}
          requiredAuth={3}
          refreshToken={refreshToken.bind(this)}
          history={history}
        />

        <ProtectedRoute
          path="/classes/:id"
          exact
          component={ClassInfo}
          prevAuth={prevAuth}
          requiredAuth={3}
          refreshToken={refreshToken.bind(this)}
          history={history}
        />

        <ProtectedRoute
          path="/classes/:id/instructors"
          exact
          component={InstructorAdd}
          prevAuth={prevAuth}
          requiredAuth={3}
          refreshToken={refreshToken.bind(this)}
          history={history}
        />
        <ProtectedRoute
          path="/classes/:id/students"
          exact
          component={StudentAdd}
          prevAuth={prevAuth}
          requiredAuth={3}
          refreshToken={refreshToken.bind(this)}
          history={history}
        />
        <ProtectedRoute
          path="/attendances/:id"
          exact
          component={AttendanceInfo}
          prevAuth={prevAuth}
          requiredAuth={3}
          refreshToken={refreshToken.bind(this)}
          history={history}
        />

        <ProtectedRoute
          path="/instructors/:user_id/classes/:id"
          exact
          component={ClassInfoInstPriv}
          prevAuth={prevAuth}
          requiredAuth={2}
          refreshToken={refreshToken.bind(this)}
          history={history}
        />

        <ProtectedRoute
          path="/students/:user_id/classes/:id"
          exact
          component={ClassInfoStudPriv}
          prevAuth={prevAuth}
          requiredAuth={1}
          refreshToken={refreshToken.bind(this)}
          history={history}
        />
        <ProtectedRoute
          path="/students/:user_id/subs"
          exact
          component={StudentSubscriptionPriv}
          prevAuth={prevAuth}
          requiredAuth={1}
          refreshToken={refreshToken.bind(this)}
          history={history}
        />

        <ProtectedRoute
          path="/users/:id"
          exact
          component={UserEdit}
          prevAuth={prevAuth}
          requiredAuth={1}
          refreshToken={refreshToken.bind(this)}
          history={history}
        />

        <Route
          exact
          strict
          path="/public-classes/:id"
          render={(props) => <ClassInfoPublic {...props} history={history} />}
        />

        <Route exact path="/logins">
          <LoginRegisteration
            token={token}
            setToken={setToken.bind(this)}
            history={history}
          />
        </Route>

        <ProtectedRoute
          path="/daily-payments"
          exact
          component={Payment}
          prevAuth={prevAuth}
          requiredAuth={4}
          refreshToken={refreshToken.bind(this)}
          history={history}
        />
        <ProtectedRoute
          path="/closings"
          exact
          component={Closing}
          prevAuth={prevAuth}
          requiredAuth={4}
          refreshToken={refreshToken.bind(this)}
          history={history}
        />

        <ProtectedRoute
          path="/users/:user_id/payslips"
          exact
          component={SalaryPayslip}
          prevAuth={prevAuth}
          requiredAuth={2}
          refreshToken={refreshToken.bind(this)}
          history={history}
        />

        <ProtectedRoute
          path="/students-report"
          exact
          component={StudentReports}
          prevAuth={prevAuth}
          requiredAuth={3}
          refreshToken={refreshToken.bind(this)}
          history={history}
        />

        <ProtectedRoute
          path="/subscriptions-catergory-report"
          exact
          component={SubscriptionCategoryReport}
          prevAuth={prevAuth}
          requiredAuth={3}
          refreshToken={refreshToken.bind(this)}
          history={history}
        />

        <ProtectedRoute
          path="/subscriptions-monthly-report"
          exact
          component={SubscriptionMonthlyReport}
          prevAuth={prevAuth}
          requiredAuth={3}
          refreshToken={refreshToken.bind(this)}
          history={history}
        />

        <Route exact path="/">
          <Home
            token={token}
            history={history}
            aboutUs={aboutUs}
            setAboutUs={setAboutUs}
            contact={contact}
            setContact={setContact}
          />
        </Route>

        <Route component={Home} />
      </Switch>
    </div>
  );
};

export default App;
