import React, { useMemo, useState, useEffect } from "react";
import {
  useTable,
  usePagination,
  useRowSelect,
  useSortBy,
  useFilters,
} from "react-table";
import { Checkbox } from "./Checkbox";
import { ColumnFilter } from "./ColumnFilter";
import { Button as SemanticButton, Icon } from "semantic-ui-react";
import "./table.scss";

function useWindowSize() {
  const [size, setSize] = useState([window.innerWidth, window.innerHeight]);
  useEffect(() => {
    const handleResize = () => {
      setSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return size;
}

export const TableMobile = (props) => {
  const [width] = useWindowSize();
  const columns = useMemo(
    () => props.createColumns(props.ButtonFunctions),
    [props.createColumns, props.ButtonFunctions]
  );
  const data = useMemo(() => props.data, [props.data]);
  const defaultColumn = useMemo(() => ({ Filter: ColumnFilter }), []);

  const tableInstance = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    prepareRow,
  } = tableInstance;

  return (
    <div id="basicTableContainer">
      <table {...getTableProps()} id="basicTable">
        <thead id="basicTableHead">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} id="basicTableHeaderRow">
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  id="basicTableHeader"
                >
                  {typeof column.render("Header") === "string"
                    ? column.render("Header")
                    : ""}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} id="basicTableBody">
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} id="basicTableRow">
                {row.cells.map((cell) => (
                  <td
                    {...cell.getCellProps()}
                    id="basicTableCell"
                    data-label={
                      typeof cell.column.Header === "function" &&
                      cell.column.Header() === ""
                        ? ""
                        : `${cell.column.Header}: `
                    }
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div id="basicTablePagination">
        <SemanticButton
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
          id="basicTableFirstPage"
        >
          <Icon name="angle double left" />
        </SemanticButton>
        <SemanticButton
          onClick={previousPage}
          disabled={!canPreviousPage}
          id="basicTablePrevPage"
        >
          <Icon name="angle left" />
        </SemanticButton>
        <SemanticButton
          onClick={nextPage}
          disabled={!canNextPage}
          id="basicTableNextPage"
        >
          <Icon name="angle right" />
        </SemanticButton>
        <SemanticButton
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
          id="basicTableLastPage"
        >
          <Icon name="angle double right" />
        </SemanticButton>
        <span id="basicTablePageInfo">
          Page{" "}
          {pageOptions.indexOf(pageOptions.find((option) => option === page)) +
            1}{" "}
          of {pageOptions.length}
        </span>
      </div>
    </div>
  );
};

TableMobile.defaultProps = {
  isSelection: false,
  selectedData: [],
  setSelectedData: null,
};
