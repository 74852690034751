import React from "react";
import { useAsyncDebounce } from "react-table";
import { Dropdown, Input } from "semantic-ui-react";
import "./table.scss";

export const ColumnFilter = ({ column }) => {
  const { filterValue, setFilter } = column;

  //   const [value, setValue] = useState(filter)
  const onChange = useAsyncDebounce((value) => {
    setFilter(value || undefined);
  }, 1000);

  const handleSort = (column, type) => {
    // Determine the next sorting state based on the current state
    if (type === "as") {
      column.toggleSortBy(false, false); // sort in ascending order
    } else if (type === "ds") {
      column.toggleSortBy(true, false); // sort in descending order
    } else {
      column.clearSortBy(); // revert to original unsorted state
    }
  };

  return (
    <div className="filter-container-table">
      <Dropdown
        icon="filter"
        floating
        // button
        className="icon"
        closeOnBlur={false}
        closeOnChange={false}
        closeOnEscape={false}
      >
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Input
              icon="search"
              iconPosition="left"
              className="search"
              onChange={(e) => {
                setFilter(e.target.value);
                onChange(e.target.value);
              }}
            />
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item
            onClick={(e) => {
              handleSort(column, "as");
            }}
          >
            Sort ascending
          </Dropdown.Item>
          <Dropdown.Item
            onClick={(e) => {
              handleSort(column, "ds");
            }}
          >
            Sort descending
          </Dropdown.Item>
          <Dropdown.Item
            onClick={(e) => {
              handleSort(column, "n");
            }}
          >
            Cancel sort
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
