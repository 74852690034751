import React, { Component } from 'react';
import { Header, Image, Button, Divider} from 'semantic-ui-react'
import {BasicTable} from './BasicTable';
import inst_icon from '../../../media/Images/instructor_icon.png';
import '../../../stylesheets/attendanceInstructorAdd.css'
import $ from 'jquery';
import track from '../../track';

class InstructorsAttendanceAdd extends Component {
  constructor(props){
    super(...arguments);
    this.state = {
      attendanceId:0,
      instructors: [],
      instructorsToAdd:[]
    }
    this.setInstructorsToAdd = this.setInstructorsToAdd.bind(this)
  }

  setInstructorsToAdd(e){
    this.setState({instructorsToAdd: e})
  }

  componentDidMount(){
    this.setState({
      instructors: this.props.instructors,
      attendanceId: this.props.attendanceId
    })
    
  }
  
  navTo = (uri) =>{
    this.props.history.push(uri);
  }

  addInstructors  = (event) =>{
    event.preventDefault();
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/attendances/${this.props.attendanceId}/instructors`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          type:'add',
          instructors: this.state.instructorsToAdd
        }),
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
          track(`added instructors ${this.state.instructorsToAdd}to the attendance of id = ${this.props.attendanceId} `,
                    'Attendance.instructors', 'Add');
                  
          //window.location.reload();
          this.props.reloadAttendance();
          return;
        },
        error: (error) => {
          alert('Unable to add instructors to the attendance. Please try your request again')
          return;
        }
      })
    }
    secondFunction();
  }
 


  render() {
    // console.log('instructorsToAdd',this.state.instructorsToAdd)
    // console.log('instructors', this.state.instructors);
    
    return <div>
                <div id='headerPartAttendanceInstAdd'>
                  <Header id='HeaderAttendanceInstAdd' as='h2' block={true} >
                    <Image size='large' circular src={inst_icon} /> Add Instructors to Attendance
                  </Header>
                </div>
                <Divider horizontal/>
                
                <div id ='tableInstAttAdd'>
                    <BasicTable key ={this.props.instructors}  data = {this.props.instructors} instructorsToAdd={this.state.instructorsToAdd} setInstructorsToAdd={this.setInstructorsToAdd} attendanceId={this.props.attendanceId} history={this.props.history}/>
                    <Divider horizontal/>
                    <Button id='attendanceInstAdd_button1' size='mini' color='red' onClick={this.addInstructors}> Add Instructors to Attendance </Button>
                </div>
                <Divider horizontal/>
           </div>
  }
  
}


export default InstructorsAttendanceAdd;
