import {format} from 'date-fns'

export const COLUMNS = [
    {
        Header: 'Date',
        accessor: 'date',
        Cell:({value}) =>{return format(new Date(value), "eeee, dd/MM/yyyy' 'hh:mm bb")}
    },
    {
        Header: 'Class',
        accessor: 'Class'
    },
    {
        Header: 'Level',
        accessor: 'level'
    },
    {
        Header: 'Alone / With a Partner',
        accessor: 'Alone / With a Partner'
    },
    {
        Header: 'Payment',
        accessor: 'Payment'
    }
]