import React, { Component, useEffect } from "react";
import "../../stylesheets/Home.css";
import PromoPart from "./PromoPart";
import WelcomePart from "./WelcomePart";
import RestPage from "./RestPage";

const Home = () => {
  return (
    <div id="container">
      <PromoPart className="sectionClassInner" />
      <WelcomePart />
      {/* <RestPage /> */}
    </div>
  );
};

export default Home;
