import React, { Component } from 'react';
import { Header, Image, Table, Icon, Divider, Button} from 'semantic-ui-react'
import payment_icon from '../../media/Images/payment.png';
import '../../stylesheets/payment.css';
import $ from 'jquery';
import {DateTimePickerComponent} from '@syncfusion/ej2-react-calendars';
import SubscriptionPaymentComponent from './SubscriptionPaymentComponent/SubscriptionPaymentComponent';
import RentPaymentComponent from './RentPaymentComponent/RentPaymentComponent';
import MiscPaymentComponent from './MiscPaymentComponent/MiscPaymentComponent';
import KitchenPaymentComponent from './KitchenPaymentComponent/KitchenPaymentComponent';
import SummaryPaymentComponent from './SummaryPaymentComponent/SummaryPaymentComponent';


class Payment extends Component {
  constructor(props){
    super(...arguments);
    this.state = {
      subscriptionPayment: [],
      rentPayment: [],
      miscPayment: [],
      kitchenPayment: [],

      subscriptionPaymentCash: 0,
      subscriptionPaymentCredit: 0,
      subscriptionPaymentTotal: 0,

      rentPaymentCash: 0,
      rentPaymentCredit: 0,
      rentPaymentTotal: 0,

      miscPaymentCash:0,

      kitchenInPaymentCash:0,
      kitchenOutPaymentCash:0,

      cashAll:0,
      creditAll:0,
      total:0,

      startDate: new Date(),
      endDate: new Date(),
    }
  }

  componentDidMount(){
    document.getElementById("sub_payments").hidden=true;
    document.getElementById("rent_payments").hidden=true;
    document.getElementById("misc_payments").hidden=true;
    document.getElementById("kitchen_payments").hidden=true;
    document.getElementById("summary_payments").hidden=true;
  }

  showSubsPayments = ()=>{
    document.getElementById("sub_payments").hidden=false;
    document.getElementById("sub_payments").scrollIntoView();
  }
  hideSubsPayments = ()=>{
    document.getElementById("sub_payments").hidden=true;
    document.getElementById("payment").scrollIntoView();
  }

  showRentPayments = ()=>{
    document.getElementById("rent_payments").hidden=false;
    document.getElementById("rent_payments").scrollIntoView();
  }
  hideRentPayments = ()=>{
    document.getElementById("rent_payments").hidden=true;
    document.getElementById("payment").scrollIntoView();
  }

  showMiscPayments = ()=>{
    document.getElementById("misc_payments").hidden=false;
    document.getElementById("misc_payments").scrollIntoView();
  }
  hideMiscPayments = ()=>{
    document.getElementById("misc_payments").hidden=true;
    document.getElementById("payment").scrollIntoView();
  }

  showKitchenPayments = ()=>{
    document.getElementById("kitchen_payments").hidden=false;
    document.getElementById("kitchen_payments").scrollIntoView();
  }
  hideKitchenPayments = ()=>{
    document.getElementById("kitchen_payments").hidden=true;
    document.getElementById("payment").scrollIntoView();
  }

  showSummary = ()=>{
    document.getElementById("summary_payments").hidden=false;
    document.getElementById("summary_payments").scrollIntoView();
  }
  hideSummary = ()=>{
    document.getElementById("summary_payments").hidden=true;
    document.getElementById("payment").scrollIntoView();
  }



  resetPayment = (event) =>{
    event.preventDefault();
    this.hideSubsPayments();
    this.hideRentPayments();
    this.hideMiscPayments();
    this.hideKitchenPayments();
    this.hideSummary();

    this.setState({
      subscriptionPayment: [],
      rentPayment: [],
      miscPayment: [],
      kitchenPayment: [],

      subscriptionPaymentCash: 0,
      subscriptionPaymentCredit: 0,
      subscriptionPaymentTotal: 0,

      rentPaymentCash: 0,
      rentPaymentCredit: 0,
      rentPaymentTotal: 0,

      miscPaymentCash:0,

      kitchenInPaymentCash:0,
      kitchenOutPaymentCash:0,

      cashAll:0,
      creditAll:0,
      total:0


    });
  }

  filterSubsPayment = (event) =>{
    event.preventDefault();
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/payments`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          type: 'get',
          start_date: this.state.startDate,
          end_date: this.state.endDate
        }),
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
          this.showSubsPayments()
          this.hideRentPayments();
          this.hideMiscPayments();
          this.hideKitchenPayments();
          this.hideSummary();
          this.setState({
            subscriptionPayment: result.subscription_payments
          });
          return;
        },
        error: (error) => {
          alert('Unable to get filtered subscription daily payments. Please try your request again')
          return;
        }
      })
    }
    secondFunction();
  }

  filterRentPayment = (event) =>{
    event.preventDefault();
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/payments`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          type: 'get',
          start_date: this.state.startDate,
          end_date: this.state.endDate
        }),
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
          this.showRentPayments()
          this.hideSubsPayments();
          this.hideMiscPayments();
          this.hideKitchenPayments();
          this.hideSummary();
          this.setState({
            rentPayment: result.rent_payments
          });
          return;
        },
        error: (error) => {
          alert('Unable to get filtered room rental daily payments. Please try your request again')
          return;
        }
      })
    }
    secondFunction();
  }

  filterMiscPayment = (event) =>{
    event.preventDefault();
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/payments`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          type: 'get',
          start_date: this.state.startDate,
          end_date: this.state.endDate
        }),
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
          this.showMiscPayments();
          this.hideRentPayments();
          this.hideSubsPayments();
          this.hideKitchenPayments();
          this.hideSummary();
          this.setState({
            miscPayment: result.misc_payments
          });
          return;
        },
        error: (error) => {
          alert('Unable to get filtered misc daily payments. Please try your request again')
          return;
        }
      })
    }
    secondFunction();
  }

  filterKitchenPayment = (event) =>{
    event.preventDefault();
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/payments`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          type: 'get',
          start_date: this.state.startDate,
          end_date: this.state.endDate
        }),
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
          this.showKitchenPayments();
          this.hideMiscPayments();
          this.hideRentPayments();
          this.hideSubsPayments();
          this.hideSummary();
          this.setState({
            kitchenPayment: result.kitchen_payments
          });
          return;
        },
        error: (error) => {
          alert('Unable to get filtered kitchen daily payments. Please try your request again')
          return;
        }
      })
    }
    secondFunction();
  }

  filterSummary = (event) =>{
    event.preventDefault();
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/payments`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          type: 'get',
          start_date: this.state.startDate,
          end_date: this.state.endDate
        }),
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
          this.showSummary()
          this.hideSubsPayments();
          this.hideRentPayments();
          this.hideMiscPayments();
          this.hideKitchenPayments();
          this.setState({
            subscriptionPaymentCash: result.payments_subscription_cash,
            subscriptionPaymentCredit: result.payments_subscription_credit,
            subscriptionPaymentTotal: result.payments_subscription_total,

            rentPaymentCash: result.payments_rent_cash,
            rentPaymentCredit: result.payments_rent_credit,
            rentPaymentTotal: result.payments_rent_total,

            miscPaymentCash: result.payments_misc_cash,

            kitchenInPaymentCash:result.payments_kitchen_in_cash,
            kitchenOutPaymentCash:result.payments_kitchen_out_cash,

            cashAll: result.cash_all,
            creditAll: result.credit_all,
            total: result.total
          });
          return;
        },
        error: (error) => {
          alert('Unable to get filtered daily payments Summary. Please try your request again')
          return;
        }
      })
    }
    secondFunction();
  }

  handleStartDate = (event) => {
    this.setState({
        startDate: event.target.value
    }, async ()=>{
      //console.log(this.state.startDate)
    });
  }

  handleEndDate = (event) => {
    this.setState({
        endDate: event.target.value
    }, async ()=>{
      //console.log(this.state.endDate)
    });
  }

  render() {

    return <div>
                <div id='headerPartPayment'>
                  <Header id='HeaderPayment' as='h2' block={true} value={this.state.room}>
                    <Image size='large' circular src={payment_icon} /> Daily Payments
                  </Header>
                </div>
                <Divider horizontal/>

              <div id='payment'>
                <Divider horizontal>
                  <h4>
                    <Icon name='payment' />
                    Payment Filter
                  </h4>
                </Divider>
                <Table definition>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell width={2}>Start Date</Table.Cell>
                      <Table.Cell>
                        <DateTimePickerComponent
                          CssClass="customClass"
                          id='StartTime'
                          className ="e-field"
                          format='dd/MM/yy'
                          value={this.state.startDate}
                          onChange={this.handleStartDate}
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell width={2}>End Date</Table.Cell>
                      <Table.Cell>
                        <DateTimePickerComponent
                          CssClass="customClass"
                          id='EndTime'
                          className ="e-field"
                          format='dd/MM/yy'
                          value={this.state.endDate}
                          onChange={this.handleEndDate}
                        />
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </div>

              <Divider horizontal/>

              <div id = 'submitPaymentContainer'>
                <Button id='submit_view_subs_payments' floated='right' size='mini' color='red' onClick={this.filterSubsPayment}> View Subscriptions </Button>
                <Button id='submit_view_rent_payments' floated='right' size='mini' color='red' onClick={this.filterRentPayment}> View Room Rentals </Button>
                <Button id='submit_view_misc_payments' floated='right' size='mini' color='red' onClick={this.filterMiscPayment}> View Miscs </Button>
                <Button id='submit_view_kitchen_payments' floated='right' size='mini' color='red' onClick={this.filterKitchenPayment}> View Kitchens </Button>
                <Button id='submit_view_summary' floated='right' size='mini' color='red' onClick={this.filterSummary}> View Summary </Button>
                <Button id='submit_reset' floated='right' size='mini' color='red' onClick={this.resetPayment}> Reset </Button>


              </div>
              <div id = 'sub_payments'>
                <SubscriptionPaymentComponent subscriptionPayment={this.state.subscriptionPayment}/>
              </div>
              <div id = 'rent_payments'>
                <RentPaymentComponent rentPayment={this.state.rentPayment}/>
              </div>

              <div id = 'misc_payments'>
                <MiscPaymentComponent miscPayment={this.state.miscPayment}/>
              </div>

              <div id = 'kitchen_payments'>
                <KitchenPaymentComponent kitchenPayment={this.state.kitchenPayment}/>
              </div>

              <div id = 'summary_payments'>
                <SummaryPaymentComponent

                subscriptionPaymentCash={this.state.subscriptionPaymentCash}
                subscriptionPaymentCredit={this.state.subscriptionPaymentCredit}
                subscriptionPaymentTotal={this.state.subscriptionPaymentTotal}

                rentPaymentCash={this.state.rentPaymentCash}
                rentPaymentCredit={this.state.rentPaymentCredit}
                rentPaymentTotal={this.state.rentPaymentTotal}

                miscPaymentCash={this.state.miscPaymentCash}

                kitchenInPaymentCash = {this.state.kitchenInPaymentCash}
                kitchenOutPaymentCash = {this.state.kitchenOutPaymentCash}


                cashAll={this.state.cashAll}
                creditAll={this.state.creditAll}
                total={this.state.total}
                />

              </div>
           </div>


  }

}


export default Payment;
