import React from "react";
import {
  Category,
  ChartComponent,
  ColumnSeries,
  LineSeries, // Added for displaying total subscriptions as a line
  Inject,
  Legend,
  SeriesCollectionDirective,
  SeriesDirective,
  Tooltip,
  DataLabel,
} from "@syncfusion/ej2-react-charts";

const Chart = ({ Summary }) => {
  const primaryxAxis = { valueType: "Category" };
  const marker = {
    visible: true,
    height: 10,
    width: 10,
    dataLabel: { visible: true },
  };

  // Grouping data by dance categories and separating them by package types
  const categories = [...new Set(Summary.map((item) => item.dance_category))]; // Unique dance categories
  const packages = [...new Set(Summary.map((item) => item.package))]; // Unique package types

  // Prepare series data for each package type
  const seriesData = packages.map((packageType) => {
    return {
      xName: "dance_category",
      yName: packageType,
      dataSource: categories.map((category) => {
        const subscription = Summary.find(
          (item) =>
            item.dance_category === category && item.package === packageType
        );
        return {
          dance_category: category,
          subscriptions_count: subscription
            ? subscription.subscriptions_count
            : 0,
        };
      }),
      name: packageType, // Package type name for the legend
      type: "Column", // Column chart type
      marker: marker,
    };
  });

  // Calculate total subscriptions per dance category
  const totalSubscriptions = categories.map((category) => {
    const total = Summary.filter(
      (item) => item.dance_category === category
    ).reduce((sum, item) => sum + item.subscriptions_count, 0);

    return {
      dance_category: category,
      total_subscriptions: total,
    };
  });

  return (
    <ChartComponent
      id={"subscriptionsChart"}
      primaryXAxis={primaryxAxis}
      title={"Subscriptions per Dance Category and Package"}
      tooltip={{ enable: true }}
    >
      <Inject
        services={[
          ColumnSeries,
          LineSeries,
          Legend,
          Category,
          Tooltip,
          DataLabel,
        ]}
      />
      <SeriesCollectionDirective>
        {seriesData.map((series, index) => (
          <SeriesDirective
            key={index}
            xName="dance_category"
            yName="subscriptions_count"
            name={series.name} // Package type name for the legend
            dataSource={series.dataSource}
            type="Column"
            marker={marker}
          />
        ))}
        {/* Total Subscriptions Series */}
        <SeriesDirective
          xName="dance_category"
          yName="total_subscriptions"
          name="Total Subscriptions"
          dataSource={totalSubscriptions}
          type="Line" // Display as a line chart
          marker={marker}
        />
      </SeriesCollectionDirective>
    </ChartComponent>
  );
};

export default Chart;
