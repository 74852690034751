import React, { Component } from 'react';
import { Header, Image, Segment, Table, Grid , Divider, Dropdown, Button} from 'semantic-ui-react'
import {format} from 'date-fns'
import '../../stylesheets/attendance.css';
import $ from 'jquery';
import attendance_icon from '../../media/Images/class.png';
import inst_icon from '../../media/Images/instructor_icon.png';

import InstructorsAttendanceRemove from './InstructorsAttendanceRemove/InstructorsAttendanceRemove'
import InstructorsAttendanceAdd from './InstructorsAttendanceAdd/InstructorsAttendanceAdd'
import MainStudValidSubAdd from './MainStudValidSubAdd/MainStudValidSubAdd'
import MainStudValidSubRemoveAttended from './MainStudValidSubRemoveAttended/MainStudValidSubRemoveAttended' 
import MainStudValidSubRemoveAbsent from './MainStudValidSubRemoveAbsent/MainStudValidSubRemoveAbsent' 
import MainStudInvalidSub from './MainStudInvalidSub/MainStudInvalidSub'
import StudentShareToInstructor from './StudentShareToInstructor/StudentShareToInstructor';


class AttendanceInfo extends Component {
  constructor(props){
    super(...arguments);
    this.state = {
      classId:1,
      attendanceTime:'',

      classInfo:{},
      level:'',
      slots_count:0,

      attendanceId:1,
      attendance: {},

      // instructorsShare:[],

      instructors_list_could_remove: [],
      instructors_list_could_add: [],
      student_main_valid_subscription_could_remove_attended: [],
      student_main_valid_subscription_could_remove_absent: [],
      student_main_valid_subscription_could_add: [],
      student_main_invalid_subscription: []
    }
  }

  reloadAttendance = async () => {
    const result = await this.props.refreshToken()
    // do something else here after firstFunction completes
    $.ajax({
      url: `/api/attendances/${this.state.attendanceId}`, //TODO: update request URL
      type: "GET",
      beforeSend: function (xhr) {   //Include the bearer token in header
        xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
      },
      success: (result) => {
        this.setState({
          classId: result.attendance.Class.id,
          attendance: result.attendance,
          // instructorsShare: result.attendance.instructors_share,

          classInfo: result.attendance.Class,
          attendanceTime: format(new Date(result.attendance.start), "eeee, dd/MM/yyyy' 'hh:mm bb"),
          level: result.attendance.Class.level.text,
          slots_count: result.attendance.Class.slots_count,

          instructors_list_could_remove : result.instructors_list_could_remove,
          instructors_list_could_add: result.instructors_list_could_add,
          student_main_valid_subscription_could_remove_attended: result.student_main_valid_subscription_could_remove_attended,
          student_main_valid_subscription_could_remove_absent: result.student_main_valid_subscription_could_remove_absent,
          student_main_valid_subscription_could_add: result.student_main_valid_subscription_could_add,
          student_main_invalid_subscription: result.student_main_invalid_subscription
        })
        return;
      },
      error: (error) => {
        alert('Unable to load attendance info. Please try your request again')
        return;
      }
    })
  }

  componentDidMount(){
    this.setState({attendanceId: this.props.match.params.id}, async () => {
      const secondFunction = async () => {
        const result = await this.props.refreshToken()
        // do something else here after firstFunction completes
        $.ajax({
          url: `/api/attendances/${this.state.attendanceId}`, //TODO: update request URL
          type: "GET",
          beforeSend: function (xhr) {   //Include the bearer token in header
            xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
          },
          success: (result) => {
            this.setState({
              classId: result.attendance.Class.id,
              attendance: result.attendance,
              
              classInfo: result.attendance.Class,
              attendanceTime: format(new Date(result.attendance.start), "eeee, dd/MM/yyyy' 'hh:mm bb"),
              level: result.attendance.Class.level.text,
              slots_count: result.attendance.Class.slots_count,

              instructors_list_could_remove : result.instructors_list_could_remove,
              instructors_list_could_add: result.instructors_list_could_add,
              student_main_valid_subscription_could_remove_attended: result.student_main_valid_subscription_could_remove_attended,
              student_main_valid_subscription_could_remove_absent: result.student_main_valid_subscription_could_remove_absent,
              student_main_valid_subscription_could_add: result.student_main_valid_subscription_could_add,
              student_main_invalid_subscription: result.student_main_invalid_subscription
            })
            return;
          },
          error: (error) => {
            alert('Unable to load attendance info. Please try your request again')
            return;
          }
        })
      }
      secondFunction();  
    })
  }
  
  createInstructor(){
    if(Number(this.state.attendance?.instructors_share?.length ?? 0) ===0){
      return(<></>)
    }else{
      return (
        <div>
          <Divider horizontal/>
          <div id='headerPartAttendanceInstAdd'>
                  <Header id='HeaderAttendanceInstAdd' as='h2' block={true} >
                    <Image size='large' circular src={inst_icon} /> Instructor Share
                  </Header>
                </div>
                <Divider horizontal/>
          {this.createInstructorShare()}
        </div>
      )
    }
    
  }

  createInstructorShare(){
    if(Number(this.state.attendance?.instructors_share?.length ?? 0) ===0){
      return(<></>)
    }else{
      return this.state.attendance?.instructors_share.map((el, i) => 
        <div key={i}>
          <Divider hidden/>
          <Table definition>
            <Table.Body>
              <Table.Row>
                <Table.Cell width={3}>
                  Instructor Name
                </Table.Cell>
                <Table.Cell>
                  {this.state.attendance.instructors_share[i].name}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width={3}>
                  Salary Base
                </Table.Cell>
                <Table.Cell>
                  {this.state.attendance.instructors_share[i].is_hourly_based}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width={3}>
                  Salary Percentage
                </Table.Cell>
                <Table.Cell>
                  {this.state.attendance.instructors_share[i].percentage * 100} %
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width={3}>
                  Salary Total Share
                </Table.Cell>
                <Table.Cell>
                  {this.state.attendance.instructors_share[i].total_instructor_share } EGP
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          {this.createInstructorSharePerStudent()}
        </div>          
      )
    }
    
  }

  createInstructorSharePerStudent(){
    if(Number(this.state.attendance?.instructors_share.length ?? 0) ===0){
      return(<></>)
    }else{
      return(<div>
        <StudentShareToInstructor students={this.state.attendance.instructors_share[0].students} attendanceId={this.state.attendanceId} history={this.props.history} refreshToken={this.props.refreshToken} reloadAttendance={this.reloadAttendance.bind(this)}/>
      </div>)
    }
    
  }

  render() {
    console.log("attendance.instructors_share = ", this.state.attendance?.instructors_share);
    console.log("len(attendance.instructors_share) = ",this.state.attendance?.instructors_share?.length ?? 0);
    
    return  <div>
              <div id='headerPartAttendance'>
                <Header id='HeaderAttendance' as='h2' block={true} >
                  <Image size='large' circular src={attendance_icon} /> Attendance Information
                </Header>
              </div>
              <Divider horizontal/>
              
              <div id ='class_description_attendance'>
                <Table definition>
                    <Table.Body>
                    <Table.Row>
                        <Table.Cell width={2}>Attendance</Table.Cell>
                        <Table.Cell>
                          {this.state.attendanceTime}
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell width={2}>Description</Table.Cell>
                        <Table.Cell>
                          {this.state.classInfo.description}
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell width={2}>Duration</Table.Cell>
                        <Table.Cell>
                          {this.state.classInfo.duration_minutes} minutes
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell width={2}>Is Fixed</Table.Cell>
                        <Table.Cell>
                          {this.state.classInfo.Is_fixed? "Fixed Class":"Onetime Class"}
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell width={2}>Weekly Slots</Table.Cell>
                        <Table.Cell>
                          {this.state.slots_count == 2? "Twice a week":this.state.slots_count == 1? "Once a week":'-'}
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell width={2}>Level</Table.Cell>
                        <Table.Cell>
                          {this.state.level}
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                </Table>
              </div>
              <Divider horizontal/>

              <div>
                  <Segment>
                    <Grid columns={2} divided>
                      <Grid.Row>
                        <Grid.Column>
                          <div>
                            <InstructorsAttendanceRemove instructors={this.state.instructors_list_could_remove} attendanceId={this.state.attendanceId} history={this.props.history} refreshToken={this.props.refreshToken} reloadAttendance={this.reloadAttendance.bind(this)}/>
                          </div>
                        </Grid.Column>
                        <Grid.Column>
                          <div>
                            <InstructorsAttendanceAdd instructors={this.state.instructors_list_could_add} attendanceId={this.state.attendanceId} history={this.props.history} refreshToken={this.props.refreshToken} reloadAttendance={this.reloadAttendance.bind(this)}/>
                          </div>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                    <Grid columns={1}>
                      <Grid.Row>
                        <Grid.Column>
                          <div>
                            <MainStudValidSubAdd students={this.state.student_main_valid_subscription_could_add} attendanceId={this.state.attendanceId} history={this.props.history} refreshToken={this.props.refreshToken} reloadAttendance={this.reloadAttendance.bind(this)}/>
                          </div>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <div>                          
                            <MainStudValidSubRemoveAttended students={this.state.student_main_valid_subscription_could_remove_attended} attendanceId={this.state.attendanceId} history={this.props.history} refreshToken={this.props.refreshToken} reloadAttendance={this.reloadAttendance.bind(this)}/>
                          </div>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <div>                          
                            <MainStudValidSubRemoveAbsent students={this.state.student_main_valid_subscription_could_remove_absent} attendanceId={this.state.attendanceId} history={this.props.history} refreshToken={this.props.refreshToken} reloadAttendance={this.reloadAttendance.bind(this)}/>
                          </div>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                    <Grid columns={1} divided>
                      <Grid.Column>
                        <MainStudInvalidSub students={this.state.student_main_invalid_subscription} attendanceId={this.state.attendanceId} history={this.props.history} refreshToken={this.props.refreshToken}/>
                      </Grid.Column>
                    </Grid>
                  </Segment>
                  <Segment id='inst_share_id'>
                    {this.createInstructor()}
                  </Segment>
                  
              </div>
              
              
              

            </div>
  }
  
}


export default AttendanceInfo;
