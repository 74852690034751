//import {format} from 'date-fns'
import { Button, Icon } from 'semantic-ui-react'

// var navTo = (uri) =>{
//     window.location.href = window.location.origin + uri;
// }

export const COLUMNS = [
    {
        Header:()=>'',
        accessor:'id',
        Cell:(props) => (
            <Button onClick={()=>{
                console.log(props.row.original);
                props.setStudentToAdd(props.row.original)
            }} ><Icon name='check square'/>Attend</Button>
        ),
        disableFilters: true
    },
    {
        Header:()=>'',
        accessor:'id2',
        Cell:(props) => (
            <Button onClick={()=>{
                console.log(props.row.original);
                props.setStudentToAbsent(props.row.original)
            }}> <Icon name='delete'/> Absent</Button>
        ),
        disableFilters: true
    },
    {
        Header:()=>'Edit Student',
        accessor:'id3',
        Cell:(props) => (
            <Button circular icon='edit' onClick={()=>{
                // console.log(props.row.values.id);
                props.history.push(`/students/${props.row.values.id}`)
            }} />
        ),
        disableFilters: true
    },
    {
        Width:10,
        Header: 'ID',
        accessor: 'user_id'
    },
    {
        Header: 'Student Name',
        accessor: 'full_name'
    },
    {
        Header: 'Due Amount (EGP)',
        accessor:'due_amount' 
    }
]