import React, { useState, useEffect } from "react";
import {
  Header,
  Image,
  Segment,
  Icon,
  Divider,
  Button,
} from "semantic-ui-react";
import reportsImage from "../../media/Images/reports.jpg";
import ActiveStudent from "./ActiveStudent";
import NoneActiveStudent from "./NoneActiveStudent";
import NoSubsStudent from "./NoSubsStudent";
import Chart from "./Chart";
import $ from "jquery";
import styles from "./StudentReport.module.scss";
import Student from "../Student/Student";

const Reports = () => {
  const [page, setPage] = useState("Chart");
  const [loading, setLoading] = useState(true);
  const [activeStudents, setActiveStudents] = useState("");

  useEffect(() => {
    $.ajax({
      url: `/api/reports/active-students`, //TODO: update request URL
      type: "GET",
      beforeSend: function (xhr) {
        //Include the bearer token in header
        xhr.setRequestHeader(
          "Authorization",
          "Bearer " + sessionStorage.getItem("access_token")
        );
      },
      success: (result) => {
        setActiveStudents(result);
        setLoading(false);
        return;
      },
      error: (error) => {
        alert(
          "Unable to load active & none active students. Please try your request again"
        );
        return;
      },
    });
  }, []);
  return (
    <>
      <div id="headerPartPayment">
        <Header id="HeaderPayment" as="h2" block={true} value={"Reports"}>
          <Image size="large" circular src={reportsImage} /> Students Report
        </Header>
      </div>
      <Divider horizontal />

      <div>
        <Button
          id="submit_view_subs_payments"
          floated="left"
          size="mini"
          color="red"
          onClick={() => setPage("Chart")}
        >
          Summary
        </Button>
        <Button
          id="submit_view_subs_payments"
          floated="left"
          size="mini"
          color="red"
          onClick={() => setPage("ActiveStudents")}
        >
          Active Subscriptions
        </Button>
        <Button
          id="submit_view_subs_payments"
          floated="left"
          size="mini"
          color="red"
          onClick={() => setPage("NoneActiveStudents")}
        >
          Expired Subscriptions
        </Button>
        <Button
          id="submit_view_subs_payments"
          floated="left"
          size="mini"
          color="red"
          onClick={() => setPage("NoSubsStudents")}
        >
          No Subscriptions
        </Button>
      </div>
      <Divider horizontal />

      {loading ? (
        <Segment loading>Loading...</Segment>
      ) : (
        <div className={styles.page}>
          {page === "Chart" && <Chart Summary={activeStudents["summary"]} />}
          {page === "ActiveStudents" && (
            <ActiveStudent
              activeStudents={
                activeStudents["students_with_active_subscription"]
              }
            />
          )}
          {page === "NoneActiveStudents" && (
            <NoneActiveStudent
              noneActiveStudents={
                activeStudents["students_with_all_expired_subscriptions"]
              }
            />
          )}
          {page === "NoSubsStudents" && (
            <NoSubsStudent
              NoSubsStudents={activeStudents["students_with_no_subscriptions"]}
            />
          )}
        </div>
      )}
    </>
  );
};

export default Reports;
