import React from "react";
import { useLocation } from "react-router-dom";
import {HomeMenu} from './HomeMenu';
import { HomeLogo } from "./HomeLogo";
import 'bootstrap/dist/css/bootstrap.min.css';

export const HomeOrMenu = ({setAboutUs, setContact}) => {
    const location = useLocation();
    //console.log('location.pathname = ', location.pathname);
  return ((location.pathname === "/" ? <HomeMenu setAboutUs={setAboutUs} setContact={setContact}/> : <HomeLogo/>));
};