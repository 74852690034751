import React, { useState, useEffect } from "react";
import { Header, Image, Segment, Divider } from "semantic-ui-react";
import reportsImage from "../../media/Images/reports.jpg";
import Chart from "./Chart";
import $ from "jquery";
import styles from "./SubscriptionCategoryReport.module.scss";
import DateFilter from "./DateFilter";

const SubscriptionCategoryReport = () => {
  const [loading, setLoading] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]); // Changed to array
  const [startDate, setStartDate] = useState(new Date()); // Changed to array
  const [endDate, setEndDate] = useState(new Date()); // Changed to array
  const [show, setShow] = useState(false); // Changed to array

  const view = () => {
    setLoading(true);
    $.ajax({
      url: `/api/reports/subs-cat-package`,
      type: "POST",
      beforeSend: function (xhr) {
        // Include the bearer token in header
        xhr.setRequestHeader(
          "Authorization",
          "Bearer " + sessionStorage.getItem("access_token")
        );
      },
      dataType: "json",
      contentType: "application/json",
      data: JSON.stringify({
        start_date: startDate, // Replace with actual start date
        end_date: endDate, // Replace with actual end date
      }), // Sending start and end date in the body
      xhrFields: {
        withCredentials: true,
      },
      crossDomain: true,
      success: (result) => {
        setSubscriptions(result);
        setLoading(false);
        setShow(true);
        return;
      },
      error: (error) => {
        alert(
          "Unable to load Subscriptions per Dance Category Report. Please try your request again"
        );
        return;
      },
    });
  };

  return (
    <>
      <div id="headerPartPayment">
        <Header id="HeaderPayment" as="h2" block={true}>
          <Image size="large" circular src={reportsImage} />
          Subscription vs. Dance Category Report
        </Header>
      </div>
      <Divider horizontal />

      <DateFilter
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        view={view}
      />

      {show ? (
        loading ? (
          <Segment loading>Loading...</Segment>
        ) : (
          <div className={styles.page}>
            <Chart Summary={subscriptions} />
          </div>
        )
      ) : (
        <></>
      )}
    </>
  );
};

export default SubscriptionCategoryReport;
