import React, { Component } from 'react';
import { Header, Image, Button, Form, Segment, Divider} from 'semantic-ui-react'
import student_icon from '../../media/Images/student.png';
import no_profile_picture from '../../media/Images/no_profile_picture.png';
import '../../stylesheets/studentEdit.css'
import $ from 'jquery';
import CardTemplate from '../CardTemplate';
import StudentSubscription from '../StudentSubscription/StudentSubscription'
import track from '../track';

class StudentEdit extends Component {
  constructor(props){
    super(...arguments);
    this.state = {
      studentId:0,
      userId:0,
      username:'',
      firstName: '',
      lastName: '',
      address: '',
      email: '',
      phone: '',
      role:'',
      Password:'',
      PasswordRe:'',

      profile_picture: null,
      imageURL: '',
      student: {}
    }
  }

  /*navTo = (uri) =>{
    window.location.href = window.location.origin + uri;
  }*/

  componentWillUnmount(){
    /*
    window.addEventListener("popstate", () => {
      this.navTo('/students');
    });
    window.onpopstate = e => {
      this.navTo('/students');
    };*/
  }

  componentDidMount(){
    document.getElementById("resetStudentEdit").hidden=true;
    this.setState({studentId: this.props.match.params.id}, async () => {
      const secondFunction = async () => {
        const result = await this.props.refreshToken()
        // do something else here after firstFunction completes
        $.ajax({
          url: `/api/students/${this.state.studentId}`, //TODO: update request URL
          type: "GET",
          beforeSend: function (xhr) {   //Include the bearer token in header
            xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
          },
          xhrFields: {
            withCredentials: true
          },
          crossDomain: true,
        
          success: (result) => {
            this.setState({
              student: result.student,
              username: result.student.username,
              userId: result.student.user_id,
              firstName: result.student.first_name,
              lastName: result.student.last_name,
              address: result.student.address,
              email: result.student.email,
              phone: result.student.phone,
              role:result.student.role
             })
            $.ajax({
                url: `/uploads/${this.state.studentId}/students`, //TODO: update request URL
                type: "GET",
                beforeSend: function (xhr) {   //Include the bearer token in header
                  xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
                },
                processData: false,
                contentType: false,
                cache: false,
                timeout: 800000,
                xhrFields: {
                  withCredentials: true
                },
                crossDomain: true,
                      
                success: (result) => {
                  this.setState({ imageURL: `data:image/jpeg;base64,${result}`})
                  return;
                },
                error: (error) => {
                  this.setState({ imageURL: no_profile_picture })
                  return;
                }
            })
            return;
          },
          error: (error) => {
            alert('Unable to load student to edit. Please try your request again')
            return;
          }
        })
      }
      secondFunction();
    })
  }

  
  studentDeleteId = (event) => {
    event.preventDefault();
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/students/${this.state.studentId}`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          delete: 'delete',
          username: this.state.username,
          user_id: this.state.userId,
          first_name: this.state.firstName,
          last_name: this.state.lastName,
          address: this.state.address,
          email: this.state.email,
          phone: this.state.phone
        }),
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
          //this.navTo('/students');
          track(`deleted student, username = ${this.state.username} `,
                    'Student', 'Delete')        
          
          this.props.history.push('/students');
          return;
        },
        error: (error) => {
          $.ajax({
            url: `/api/students/${this.state.studentId}`, //TODO: update request URL
            type: "POST",
            beforeSend: function (xhr) {   //Include the bearer token in header
              xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
            },
            dataType: 'json',
            contentType: 'application/json',
            data: JSON.stringify({
              delete: 'deactivate',
              username: this.state.username,
              user_id: this.state.userId,
              first_name: this.state.firstName,
              last_name: this.state.lastName,
              address: this.state.address,
              email: this.state.email,
              phone: this.state.phone
            }),
            xhrFields: {
              withCredentials: true
            },
            crossDomain: true,
            success: (result) => {
              //this.navTo('/students');
              track(`deleted student, username = ${this.state.username} `,
                    'Student', 'Delete')        
          
              this.props.history.push('/students');
              return;
            },
            error: (error) => {
              alert('Unable to delete student. Please try your request again')
              return;
            }
          })
          return;
        }
      })
    }
    secondFunction();
  }

  
  studentEditId = (event) => {
    event.preventDefault();
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/students/${this.state.studentId}`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          delete: 'edit',
          username: this.state.username,
          user_id: this.state.userId,
          first_name: this.state.firstName,
          last_name: this.state.lastName,
          address: this.state.address,
          email: this.state.email,
          phone: this.state.phone
        }),
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
          //this.navTo('/students');
          track(`Updated student, username = ${this.state.username} `,
                    'Student', 'Update')
          
          this.props.history.push('/students');
          return;
        },
        error: (error) => {
          alert(`Unable to update student - ${typeof error?.responseJSON?.message ===typeof "yes" ? error?.responseJSON?.message : "You have been Idol for a while, please refresh page"}`)
          return;
        }
      })
    }
    secondFunction();
  }

  /*navTo = (uri) =>{
    window.location.href = window.location.origin + uri;
  }*/

  cancelId = ()=>{
    //this.navTo(`/students`)
    this.props.history.push('/students');
    return;
  }

  
  handleFirstName = (event) =>{
    this.setState({firstName:event.target.value})
  }

  handleLastName = (event) =>{
    this.setState({lastName:event.target.value})
  }

  handleAddress = (event) =>{
    this.setState({address:event.target.value})
  }

  handleEmail = (event) =>{
    this.setState({email:event.target.value})
  }

  handlePhone = (event) =>{
    this.setState({phone:event.target.value})
  }

  editProfilePictureShow = () =>{
    //document.getElementById('profile_picture_change').hidden= false;
  }

  handleProfilePicture = (event)=>{
    this.setState({profile_picture: event.target.files[0]})
  }



  changeProfilePicture = (e)=>{
    e.preventDefault();
  
    var data1 = new FormData();
    data1.append('file', this.state.profile_picture);
    data1.append('filename', this.state.username);
    
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/uploads/${this.state.studentId}/students`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        enctype: 'multipart/form-data',
        data: data1,
        processData: false,
        contentType: false,
        cache: false,
        timeout: 800000,
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
                      
        success: (result) => {
          track(`Updated student's picture of username = ${this.state.username} `,
                    'Student', 'Update')
          
          this.setState({ imageURL: URL.createObjectURL(this.state.profile_picture) })
          return;
        },
        error: (error) => {
          alert('Unable to change profile picture. Please try your request again')
          return;
        }
      })
    }
    secondFunction();
  }

  handlePassword = (event) =>{
    this.setState({Password:event.target.value})
  }

  handlePasswordRe = (event) =>{
    this.setState({PasswordRe:event.target.value})
  }

  reset_password = ()=>{
    if (document.getElementById("resetStudentEdit").hidden){
      document.getElementById("resetStudentEdit").hidden=false;
      document.getElementById("resetStudentEdit").scrollIntoView();
    }else{
      document.getElementById("resetStudentEdit").hidden=true;
    } 
  }


  resetPassword = (event) =>{
    event.preventDefault();
    if(this.state.Password === '' || this.state.PasswordRe === '' || this.state.Password !== this.state.PasswordRe ){
      alert("Unable to change password, please enter a valid password")
    } else{
      const secondFunction = async () => {
        const result = await this.props.refreshToken()
        // do something else here after firstFunction completes
        $.ajax({
          url: `/api/students/${this.state.studentId}/resetPassword`, //TODO: update request URL
          type: "POST",
          beforeSend: function (xhr) {   //Include the bearer token in header
            xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
          },
          dataType: 'json',
          contentType: 'application/json',
          data: JSON.stringify({
            password: this.state.Password,
            re_password: this.state.PasswordRe
          }),
          xhrFields: {
            withCredentials: true
          },
          crossDomain: true,
          success: (result) => {
            track(`Reset Password of student, username = ${this.state.username} `,
                    'Student', 'Update')          
            
            document.getElementById("resetStudentEdit").hidden=true;
            return;
          },
          error: (error) => {
            alert(`Unable to reset Password - ${typeof error?.responseJSON?.message ===typeof "yes" ? error?.responseJSON?.message : "You have been Idol for a while, please refresh page"}`)
            return;
          }
        })
      }  
      secondFunction();
    }
  }

  render() {

    return <div>
                <div id='headerPartStudentEdit'>  
                  <Header id='HeaderStudentEdit' as='h2' block={true}>
                      <Image size='large' circular src={this.state.imageURL} /> {this.state.firstName +" "+ this.state.lastName}
                  </Header>
                </div>
                <Divider horizontal/>
                
                <div id ='containerStudentEdit'>
                  <div id='cardStudentEdit'>
                    <CardTemplate
                    name={this.state.firstName +" "+ this.state.lastName}
                    img ={this.state.imageURL}
                    role = {this.state.role}
                    email = {this.state.email}
                    phoneNumber = {this.state.phone} 
                    onClick= {this.editProfilePictureShow}
                    ></CardTemplate>
                  </div>

                  <div id='addFormStudentEdit'>
                    <Segment id="addStudentEdit" inverted>
                      <Form id='formStudentEdit' inverted>
                        <Form.Group>
                          <Form.Field className='formHalfWidthStudentEdit'>
                              <label className="labelStudentEdit">Username</label>
                              <input className ='input' defaultValue={this.state.username} readOnly/>
                          </Form.Field>
                          <Form.Field className='formHalfWidthStudentEdit'>
                              <label className="labelStudentEdit">User ID</label>
                              <input className ='input' type='number' value={this.state.userId} readOnly/>
                          </Form.Field>
                        </Form.Group>
                        <Form.Group>
                          <Form.Field className='formHalfWidthStudentEdit'>
                              <label className="labelStudentEdit">First Name</label>
                              <input className ='input' defaultValue={this.state.firstName} onChange={this.handleFirstName}/>
                          </Form.Field>
                          <Form.Field className='formHalfWidthStudentEdit'>
                              <label className="labelStudentEdit">Last Name</label>
                              <input className ='input' defaultValue={this.state.lastName} onChange={this.handleLastName}/>
                          </Form.Field>
                        </Form.Group>
                        <Form.Group>
                          <Form.Field className='fullWidthFormStudentEdit'>
                              <label className="labelStudentEdit">Address</label>
                              <input className ='input' defaultValue={this.state.address} onChange={this.handleAddress}/>
                          </Form.Field>
                        </Form.Group>
                        <Form.Group>
                          <Form.Field className='formHalfWidthStudentEdit'>
                              <label className="labelStudentEdit">E-Mail</label>
                              <input className ='input' defaultValue={this.state.email} onChange={this.handleEmail}/>
                          </Form.Field>
                          <Form.Field className='formHalfWidthStudentEdit'>
                              <label className="labelStudentEdit">Phone</label>
                              <input className ='input' defaultValue={this.state.phone} onChange={this.handlePhone}/>
                          </Form.Field>
                        </Form.Group>
                        <div>
                          <Button id='submitStudentEdit1' content='Primary' floated='right' type='submit' onClick={this.studentEditId}>Update</Button>
                          <Button id='submitStudentEdit2' content='Secondary' floated='right' type='submit' onClick={this.studentDeleteId}>Delete</Button>
                          <Button id='submitStudentEdit3' content='Secondary' floated='right' type='submit' onClick={this.cancelId}>Cancel</Button>
                        </div>
                      </Form>
                    </Segment>
                  </div>
                </div>
                <Divider horizontal/>
                
                <div id='profilePictureChangeStudentEdit'>
                  <Segment>
                    <Form>
                      <input type='file' accept="image/*" onChange={this.handleProfilePicture}></input>
                      <Button id='submitStudentEdit4' onClick={this.changeProfilePicture}>Change Profile Picture</Button>
                    </Form>
                  </Segment>
                </div>
                <Divider horizontal/>
                
                <div >
                  <Button id='student_reset_password' size='mini' color='red' onClick={this.reset_password}> Reset Password </Button>
                </div>
                
                <div id='resetPasswordStudentEdit'>
                <Segment id="resetStudentEdit" inverted>
                  <Form id='formResetPasswordStudent' inverted>
                    <Form.Group>
                      <Form.Field className='formHalfWidthStudent'>
                        <label className="labelStudent">Password</label>
                        <input className='input' type='password' placeholder='Password' onChange={this.handlePassword}/>
                      </Form.Field>
                      <Form.Field className='formHalfWidthStudent'>
                        <label className="labelStudent">Password Re-enter</label>
                        <input className='input' type='password' placeholder='Password Re-enter' onChange={this.handlePasswordRe}/>
                      </Form.Field>
                    </Form.Group>
                    <Button id='submitResetPasswordStudent' floated='right' type='submit' onClick={this.resetPassword}>Submit</Button>
                  </Form>
                </Segment>
              </div>
              <Divider horizontal/>
                
                <div id='subscriptions'>
                  <StudentSubscription studentId = {this.props.match.params.id} imageURL={this.state.imageURL} studentName={this.state.firstName} refreshToken={this.props.refreshToken} history={this.props.history}/>
                </div>
           </div>
  }
  
}


export default StudentEdit;
