import React, { Component } from 'react';
import { Header, Image, Button, Dropdown, Segment, Form, Divider } from 'semantic-ui-react'
import { BasicTable } from './BasicTable';
import inst_icon from '../../media/Images/instructor_icon.png';
import '../../stylesheets/instructor.css'
import $ from 'jquery';
import track from '../track';

class Instructor extends Component {
  constructor(props) {
    super(...arguments);
    this.state = {
      instructors: [],
      dance_categories: [],
      dances_options: [],
      dances: [],

      UserName: '',
      FirstName: '',
      LastName: '',
      Password: '',
      PasswordRe: '',
      ProfilePicture: null,
      Email: '',
      Address: '',
      Phone: '',
      dance_category_ids: [],
      dancesInst: [],
      salaryBase: 'Hourly Based',
      isHoulyBased: true,
      salaryHourAlone: 0,
      salaryHourWithPartner: 0,
      salaryPercentage: 0,

      values: [''],

      salaryBaseOptions: [
        {
          key: 'Hourly Based',
          text: 'Hourly Based',
          value: 'Hourly Based'
        },
        {
          key: 'Percentage Based',
          text: 'Percentage Based',
          value: 'Percentage Based'
        }
      ]
    }
  }

  componentDidMount() {
    document.getElementById("addInstructor").hidden = true;
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/categories`, //TODO: update request URL
        type: "GET",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer ' + sessionStorage.getItem("access_token"));
        },
        success: (result) => {
          this.setState({ dance_categories: result.dance_categories })
          return;
        },
        error: (error) => {
          alert('Unable to load dance categories. Please try your request again')
          return;
        }
      })

      $.ajax({
        url: `/api/dances`, //TODO: update request URL
        type: "GET",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer ' + sessionStorage.getItem("access_token"));
        },
        success: (result) => {
          this.setState({ dances: result.dances })
          return;
        },
        error: (error) => {
          alert('Unable to load dances. Please try your request again')
          return;
        }
      })
      $.ajax({
        url: `/api/instructors`, //TODO: update request URL
        type: "GET",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer ' + sessionStorage.getItem("access_token"));
        },
        success: (result) => {
          this.setState({ instructors: result.instructors })
          return;
        },
        error: (error) => {
          alert('Unable to load instructors. Please try your request again')
          return;
        }
      })
    }
    secondFunction();
  }

  new_instructor = () => {
    if (document.getElementById("addInstructor").hidden) {
      document.getElementById("addInstructor").hidden = false;
      document.getElementById("addInstructor").scrollIntoView();
    } else {
      document.getElementById("addInstructor").hidden = true;
      document.getElementById("tableInstructor").scrollIntoView();
    }
  }

  handleUserName = (event) => {
    this.setState({ UserName: event.target.value })
  }

  handleFirstName = (event) => {
    this.setState({ FirstName: event.target.value })
  }

  handleLastName = (event) => {
    this.setState({ LastName: event.target.value })
  }

  handleAddress = (event) => {
    this.setState({ Address: event.target.value })
  }

  handleEmail = (event) => {
    this.setState({ Email: event.target.value })
  }

  handlePhone = (event) => {
    this.setState({ Phone: event.target.value })
  }

  handlePassword = (event) => {
    this.setState({ Password: event.target.value })
  }

  handlePasswordRe = (event) => {
    this.setState({ PasswordRe: event.target.value })
  }

  handleProfilePicture = (event) => {
    this.setState({ ProfilePicture: event.target.files[0] })
  }

  handleChangeIsHourlyBased = (event, { value }) => {
    // eslint-disable-next-line
    if (value == 'Hourly Based') {
      document.getElementById("salary_partner").hidden = false;
      this.setState({
        salaryBase: value,
        isHoulyBased: true
      })
    } else {
      document.getElementById("salary_partner").hidden = true;
      this.setState({
        salaryBase: value,
        isHoulyBased: false
      })
    }
  }

  handleSalary = (event) => {
    // eslint-disable-next-line
    if (this.state.isHoulyBased == true) {
      this.setState({
        salaryHourAlone: event.target.value,
        salaryPercentage: 0
      })
    } else {
      this.setState({
        salaryHourAlone: 0,
        salaryPercentage: event.target.value
      })
    }
  }

  handleSalaryPartner = (event) => {
    this.setState({
      salaryHourWithPartner: event.target.value
    })
  }

  addClick() {
    this.setState(prevState => ({ values: [...prevState.values, ''] }))
  }

  removeClick(i) {
    let values = [...this.state.values];
    values.splice(i, 1);

    let d = [...this.state.dancesInst];
    d.splice(i, 1);

    let dance_cats = [...this.state.dance_category_ids];
    dance_cats.splice(i, 1);

    this.setState({ values, dancesInst: d, dance_category_ids: dance_cats });
  }

  handleChangeCategory = (i, event, { value }) => {
    let dance_category_ids = [...this.state.dance_category_ids];
    dance_category_ids[i] = value;
    this.setState({ dance_category_ids: dance_category_ids }, async () => {
      let dances_options = [...this.state.dances_options];
      dances_options[i] = this.state.dances[this.state.dance_category_ids[i]];
      this.setState({
        dances_options: dances_options
      }, async () => {
        //console.log(this.state.dances_options)
      })
    })
  }

  handleChangeDance = (i, event, { value }) => {
    let d = [...this.state.dancesInst];
    d[i] = value;
    this.setState({ dancesInst: d });
  }

  createUI() {
    return this.state.values.map((el, i) =>
      <div key={i}>
        <Divider horizontal />
        <Form.Group>
          <Form.Field id='danceCatDropdown'>
            <label className="labelInstructor">Dance Category</label>
            <Dropdown compact
              placeholder='Select Category'
              selection
              options={this.state.dance_categories}
              onChange={this.handleChangeCategory.bind(this, i)}
            />
          </Form.Field>

          <Form.Field id='danceDropdown'>
            <label className="labelInstructor">Dance</label>
            <Dropdown compact
              placeholder='Select Dance'
              selection
              options={this.state.dances_options[i]}
              onChange={this.handleChangeDance.bind(this, i)}
            />
          </Form.Field>
          <Form.Field id='removeField'>
            <Button id='remove' circular icon='circular remove' onClick={this.removeClick.bind(this, i)} />
          </Form.Field>
        </Form.Group>
        <Divider horizontal />
      </div>
    )
  }

  addInstructor = (event) => {
    event.preventDefault();

    let x = 0;
    if (this.state.FirstName !== '') { x = x + 1; }
    if (this.state.LastName !== '') { x = x + 1; }

    if (this.state.salaryBase === 'Percentage Based' && (this.state.salaryPercentage > 100 || this.state.salaryPercentage < 0)) {
      alert('Salary percentage must be between 0 to 100%. Please correct it and try your request again')
    }
    else if (this.state.UserName === '') {
      alert("Please enter a unique Username to be able to sign up")
    } else if (x < 1) {
      alert("Please enter your name to be able to sign up")
    }
    else if (this.state.Email === '') {
      alert("Please enter your E-mail to be able to sign up")
    }
    else if (this.state.Phone === '') {
      alert("Please enter your phone number to be able to sign up")
    }
    else if (this.state.Password === '' || this.state.PasswordRe === '' || this.state.Password !== this.state.PasswordRe) {
      alert("Please check your password to be able to sign up")
    } else {
      const secondFunction = async () => {
        const result = await this.props.refreshToken()
        // do something else here after firstFunction completes
        $.ajax({
          url: `/api/instructors`, //TODO: update request URL
          type: "POST",
          beforeSend: function (xhr) {   //Include the bearer token in header
            xhr.setRequestHeader("Authorization", 'Bearer ' + sessionStorage.getItem("access_token"));
          },
          dataType: 'json',
          contentType: 'application/json',
          data: JSON.stringify({
            username: this.state.UserName,
            first_name: this.state.FirstName,
            last_name: this.state.LastName,
            password: this.state.Password,
            re_password: this.state.PasswordRe,
            address: this.state.Address,
            email: this.state.Email,
            phone: this.state.Phone,
            dance_cat_ids: this.state.dance_category_ids,
            dances_instructor: this.state.dancesInst,
            salary_base: this.state.salaryBase,
            salary_hour_alone: this.state.salaryHourAlone,
            salary_hour_with_partner: this.state.salaryHourWithPartner,
            salary_percentage: this.state.salaryPercentage / 100
          }),
          xhrFields: {
            withCredentials: true
          },
          crossDomain: true,
          success: (result) => {
            if (this.state.ProfilePicture !== null) {
              var data1 = new FormData();
              data1.append("file", this.state.ProfilePicture)
              data1.append("filename", this.state.UserName)

              $.ajax({
                url: `/uploads/${result.instructor.id}/instructors`, //TODO: update request URL
                type: "POST",
                beforeSend: function (xhr) {   //Include the bearer token in header
                  xhr.setRequestHeader("Authorization", 'Bearer ' + sessionStorage.getItem("access_token"));
                },
                enctype: 'multipart/form-data',
                data: data1,
                processData: false,
                contentType: false,
                cache: false,
                timeout: 800000,

                xhrFields: {
                  withCredentials: true
                },
                crossDomain: true,
                success: (result) => {
                  track(`Added new instructor, username = ${this.state.UserName} with picture`,
                    'Instructor', 'Add')
                  
                  document.getElementById("addInstructor").hidden = true;
                  $.ajax({
                    url: `/api/instructors`, //TODO: update request URL
                    type: "GET",
                    beforeSend: function (xhr) {   //Include the bearer token in header
                      xhr.setRequestHeader("Authorization", 'Bearer ' + sessionStorage.getItem("access_token"));
                    },
                    success: (result) => {
                      this.setState({ instructors: result.instructors });
                      document.getElementById("tableInstructor").scrollIntoView();
                      return;
                    },
                    error: (error) => {
                      alert('Unable to load instructors. Please try your request again')
                      return;
                    }
                  })
                  return;
                },
                error: (error) => {
                  alert('Unable to add the profile picture. Please try your request again')
                  return;
                }
              })
            } else {
              track(`Added new instructor, username = ${this.state.UserName} without picture`,
                    'Instructor', 'Add')
                  
              document.getElementById("addInstructor").hidden = true;
              $.ajax({
                url: `/api/instructors`, //TODO: update request URL
                type: "GET",
                beforeSend: function (xhr) {   //Include the bearer token in header
                  xhr.setRequestHeader("Authorization", 'Bearer ' + sessionStorage.getItem("access_token"));
                },
                success: (result) => {
                  this.setState({ instructors: result.instructors });
                  document.getElementById("tableInstructor").scrollIntoView();
                  return;
                },
                error: (error) => {
                  alert('Unable to load instructors. Please try your request again')
                  return;
                }
              })
              return;
            }
            return;
          },
          error: (error) => {
            alert(`Unable to add instructor - ${typeof error?.responseJSON?.message === typeof "yes" ? error?.responseJSON?.message : "You have been Idol for a while, please refresh page"}`)
            return;
          }
        })
      }
      secondFunction();
    }
  }



  render() {

    return <div>
      <div id='headerPartInstructor'>
        <Header id='HeaderInstructor' as='h2' block={true} value={this.state.room}>
          <Image size='large' circular src={inst_icon} /> Instructors
        </Header>
      </div>
      <Divider horizontal />

      <div >
        <Button id='instructor_button1' size='mini' color='red' onClick={this.new_instructor}> Add Instructor </Button>
      </div>
      <Divider horizontal />

      <div id="tableInstructor">
        <BasicTable key={this.state.instructors} data={this.state.instructors} />
      </div>
      <Divider horizontal />

      <div id='addFormInstructor'>
        <Segment id="addInstructor" inverted>
          <Form id='formInstructor' inverted>
            <Form.Group>
              <Form.Field className='fullWidthFormInstructor'>
                <label className="labelInstructor">User Name</label>
                <input className='input' placeholder='User Name' onChange={this.handleUserName} />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field className='formHalfWidthInstructor'>
                <label className="labelInstructor">First Name</label>
                <input className='input' placeholder='First Name' onChange={this.handleFirstName} />
              </Form.Field>
              <Form.Field className='formHalfWidthInstructor'>
                <label className="labelInstructor">Last Name</label>
                <input className='input' placeholder='Last Name' onChange={this.handleLastName} />
              </Form.Field>
            </Form.Group>

            <Form.Group>
              <Form.Field className='formHalfWidthInstructor'>
                <label className="labelInstructor">Password</label>
                <input className='input' type='password' placeholder='Password' onChange={this.handlePassword} />
              </Form.Field>
              <Form.Field className='formHalfWidthInstructor'>
                <label className="labelInstructor">Password Re-enter</label>
                <input className='input' type='password' placeholder='Password Re-enter' onChange={this.handlePasswordRe} />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field className='fullWidthFormInstructor'>
                <label className="labelInstructor">Profile Picture</label>
                <input className='input' type='file' placeholder="Choose Instructor's Profile Picture" accept="image/*" onChange={this.handleProfilePicture} />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field className='fullWidthFormInstructor'>
                <label className="labelInstructor">Address</label>
                <input className='input' placeholder='Address' onChange={this.handleAddress} />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field className='formHalfWidthInstructor'>
                <label className="labelInstructor">E-Mail</label>
                <input className='input' placeholder='E-Mail' onChange={this.handleEmail} />
              </Form.Field>
              <Form.Field className='formHalfWidthInstructor'>
                <label className="labelInstructor">Phone</label>
                <input className='input' placeholder='Phone' onChange={this.handlePhone} />
              </Form.Field>
            </Form.Group>

            <Form.Group>
              <Form.Field className='fullWidthFormInstructor'>
                <label className="labelInstructor">Is Hourly Based?</label>
                <Dropdown
                  placeholder='Is Hourly Based?'
                  selection
                  options={this.state.salaryBaseOptions}
                  onChange={this.handleChangeIsHourlyBased}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field className='formHalfWidthInstructor'>
                <label className="labelInstructor">{this.state.salaryBase === 'Hourly Based' ? 'Salary - Holds Class Alone (EGP/Hour)' : 'Salary (%)'}</label>
                <input className='input' type='number' placeholder={this.state.salaryBase === 'Hourly Based' ? 'Salary - Holds Class Alone (EGP/Hour)' : 'Salary (%)'}
                  onChange={this.handleSalary} />
              </Form.Field>
              <Form.Field className='formHalfWidthInstructor' id='salary_partner'>
                <label className="labelInstructor"> Salary - Holds Class With Partner (EGP/Hour)</label>
                <input className='input' type='number' placeholder='Salary - Holds Class With Partner (EGP/Hour)'
                  onChange={this.handleSalaryPartner} />
              </Form.Field>
            </Form.Group>

            {this.createUI()}
            <Button id='add_more_button_new' className='formHalfWidthInstructor' primary onClick={this.addClick.bind(this)}>add more</Button>
            <Divider horizontal />
            <Button id='submitInstructor' floated='right' type='submit' onClick={this.addInstructor}>Submit</Button>
          </Form>
        </Segment>
      </div>


    </div>
  }

}


export default Instructor;
