import React, { useState, useEffect } from "react";
import { Header, Image, Segment, Divider } from "semantic-ui-react";
import reportsImage from "../../media/Images/reports.jpg";
import Chart from "./Chart";
import $ from "jquery";
import styles from "./SubscriptionCategoryReport.module.scss";
import DateFilter from "./DateFilter";

const SubscriptionCategoryReport = () => {
  const [subscriptions, setSubscriptions] = useState([]); // Changed to array
  const [year, setYear] = useState(new Date().getFullYear()); // Changed to array
  const [show, setShow] = useState(false);

  const view = () => {
    setSubscriptions([]);
    $.ajax({
      url: `/api/reports/subscriptions-per-category/${year}`,
      type: "GET",
      beforeSend: function (xhr) {
        // Include the bearer token in header
        xhr.setRequestHeader(
          "Authorization",
          "Bearer " + sessionStorage.getItem("access_token")
        );
      },
      dataType: "json",
      contentType: "application/json",
      xhrFields: {
        withCredentials: true,
      },
      crossDomain: true,
      success: (result) => {
        console.log(result);
        setSubscriptions(result);
        return;
      },
      error: (error) => {
        alert(
          "Unable to load Subscriptions per month Report. Please try your request again"
        );
        return;
      },
      complete: () => {
        setShow(true);
      },
    });
  };

  return (
    <>
      <div id="headerPartPayment">
        <Header id="HeaderPayment" as="h2" block={true}>
          <Image size="large" circular src={reportsImage} />
          Monthly Subscriptions per Dance Category
        </Header>
      </div>
      <Divider horizontal />

      <DateFilter year={year} setYear={setYear} view={view} />

      {show ? (
        subscriptions.length === 0 ? (
          <Segment loading>Loading...</Segment>
        ) : (
          <div className={styles.page}>
            <Chart data={subscriptions} year={year} />
          </div>
        )
      ) : (
        <></>
      )}
    </>
  );
};

export default SubscriptionCategoryReport;
