import { Icon, Segment, Divider } from "semantic-ui-react";
import { Button } from "semantic-ui-react";
import { Link } from "react-router-dom";

export const createColumns = (t, onEdit, prepDeleteModal) => {
  return [
    {
      width: 30,
      Header: () => "",
      accessor: "id",
      Cell: ({ value }) => (
        <Link to={`/students/${value}`}>
          <Button circular icon="edit" />
        </Link>
      ),
      disableFilters: true,
    },
    {
      maxWidth: 10,
      Header: "ID",
      accessor: "user_id",
    },
    {
      Header: "Name",
      accessor: "full_name",
    },
    {
      Header: "Address",
      accessor: "address",
    },
    {
      Header: "E-Mail",
      accessor: "email",
    },
    {
      Header: "Phone Number",
      accessor: "phone",
    },
  ];
};
