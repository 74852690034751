import React, { Component } from 'react';
import { Header, Image, Button, Divider} from 'semantic-ui-react'
import {BasicTable} from './BasicTable';
import stud_icon from '../../../media/Images/student.png';
import '../../../stylesheets/attendanceStudentAdd.css'
import $ from 'jquery';
import track from '../../track';

class MainStudValidSubAdd extends Component {
  constructor(props){
    super(...arguments);
    this.state = {
      attendanceId:0,
      students: []
    }

    
    this.setStudentToAdd = this.setStudentToAdd.bind(this)
    this.setStudentToAbsent = this.setStudentToAbsent.bind(this)
    
  }
  
  
  setStudentToAdd(stud){
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/attendances/${this.props.attendanceId}/students`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          type:'add',
          student: stud,
          is_absent: "no"
        }),
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
          //window.location.reload();
          track(`Attended student ${stud}to the attendance of id = ${this.props.attendanceId} `,
                    'Attendance.students', 'Add');
                  
          
          this.props.reloadAttendance();
          return;
        },
        error: (error) => {
          alert('Unable to add students to the attendance. Please try your request again')
          return;
        }
      })  
    }
    secondFunction();
  }
  
  setStudentToAbsent(stud){
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/attendances/${this.props.attendanceId}/students`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          type:'add',
          student: stud,
          is_absent: "yes"
        }),
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
          //window.location.reload();
          track(`Absent student ${stud}to the attendance of id = ${this.props.attendanceId} `,
                    'Attendance.students', 'Add');
                  
          
          this.props.reloadAttendance();
          return;
        },
        error: (error) => {
          alert('Unable to add students to the attendance. Please try your request again')
          return;
        }
      })  
    }
    secondFunction();
  }
  

  componentDidMount(){
    this.setState({
      students: this.props.students,
      attendanceId: this.props.attendanceId
    })
    
  }
  

 


  render() {
    
    return <div>
                <div id='headerPartAttendanceStudAdd'>
                  <Header id='HeaderAttendanceStudAdd' as='h2' block={true} >
                    <Image size='large' circular src={stud_icon} /> Add Students to Attendance
                  </Header>
                </div>
                <Divider horizontal/>

                <div id='tableStudAttAdd'>
                    <BasicTable key ={this.props.students}  data = {this.props.students} setStudentToAdd={this.setStudentToAdd} setStudentToAbsent={this.setStudentToAbsent} attendanceId={this.props.attendanceId} history={this.props.history}/>
                    <Divider horizontal/>
                </div>
                <Divider horizontal/>
           </div>
  }
  
}


export default MainStudValidSubAdd;
