import React, { useEffect, useMemo, useState } from "react";
import {
  useFilters,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { Checkbox } from "./Checkbox";
import { ColumnFilter } from "./ColumnFilter";
import { Button as SemanticButton, Icon } from "semantic-ui-react";
import "./table.scss";

export const TableDesktop = (props) => {
  const {
    createColumns,
    ButtonFunctions,
    isSelection,
    selectedData,
    setSelectedData,
  } = props;
  const columns = useMemo(
    () => createColumns(ButtonFunctions),
    [createColumns, ButtonFunctions]
  );
  const data = useMemo(() => props.data, [props.data]);
  const defaultColumn = useMemo(() => ({ Filter: ColumnFilter }), []);

  const [filteringColumnId, setFilteringColumnId] = useState(null);

  const handleSort = (column) => {
    if (!column.isSorted) {
      column.toggleSortBy(false, false);
    } else if (!column.isSortedDesc) {
      column.toggleSortBy(true, false);
    } else {
      column.clearSortBy();
    }
  };

  const tableHooks = [useFilters, useSortBy, usePagination];
  if (isSelection) {
    tableHooks.push(useRowSelect, (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <Checkbox {...getToggleAllRowsSelectedProps()} />
          ),
          Cell: ({ row }) => <Checkbox {...row.getToggleRowSelectedProps()} />,
          disableFilters: true,
          disableSortBy: true,
        },
        ...columns,
      ]);
    });
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    state,
    prepareRow,
    selectedFlatRows,
  } = useTable({ columns, data, defaultColumn }, ...tableHooks);

  const { pageIndex } = state;

  useEffect(() => {
    if (isSelection && setSelectedData) {
      const selectedRows = selectedFlatRows.map((row) => row.original);
      if (JSON.stringify(selectedRows) !== JSON.stringify(selectedData)) {
        setSelectedData(selectedRows);
      }
    }
  }, [selectedFlatRows, isSelection, selectedData, setSelectedData]);

  return (
    <div key={props.data} className="basic-table-container-tb-desktop">
      <table
        id={`table-tb-desktop-${
          props.isScrollEnabled ? "scroll-enabled" : "scroll-disabled"
        }`}
        {...getTableProps()}
        onClick={() => setFilteringColumnId(null)}
        className="table-tb-desktop"
      >
        <thead className="table-head-tb-desktop">
          {headerGroups.map((headerGroup) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              className="table-row-header-tb-desktop"
            >
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  onClick={(e) => e.stopPropagation()}
                  className="table-header-tb-desktop"
                >
                  {!column.disableFilters ? (
                    <div className="header-cell-tb-desktop">
                      {column.render("Header")}
                      <div
                        style={{ width: "10%", paddingRight: "5%" }}
                        className="header-filter-tb-desktop"
                      >
                        {column.render("Filter")}
                      </div>
                    </div>
                  ) : (
                    <> {column.render("Header")} </>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className="table-body-tb-desktop">
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className="table-row-tb-desktop">
                {row.cells.map((cell) => (
                  <td
                    {...cell.getCellProps()}
                    className="table-cell-tb-desktop"
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="prevnext-tb-desktop">
        <SemanticButton
          icon
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
          className="button-tb-desktop"
        >
          <Icon name="double angle left" />
        </SemanticButton>
        <SemanticButton
          icon
          onClick={previousPage}
          disabled={!canPreviousPage}
          className="button-tb-desktop"
        >
          <Icon name="left arrow" /> Previous
        </SemanticButton>
        <SemanticButton
          icon
          onClick={nextPage}
          disabled={!canNextPage}
          className="button-tb-desktop"
        >
          Next <Icon name="right arrow" />
        </SemanticButton>
        <SemanticButton
          icon
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
          className="button-tb-desktop"
        >
          <Icon name="double angle right" />
        </SemanticButton>
        <span className="page-info-tb-desktop">
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </span>
        <span className="goto-page-tb-desktop">
          | Go to page:{" "}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const pageNumber = e.target.value
                ? Number(e.target.value) - 1
                : 0;
              gotoPage(pageNumber);
            }}
            style={{ width: "50px", textAlign: "center" }}
            className="input-page-tb-desktop"
          />
        </span>
      </div>
    </div>
  );
};

TableDesktop.defaultProps = {
  isSelection: false,
  selectedData: [],
  setSelectedData: null,
  isScrollEnabled: null,
};
