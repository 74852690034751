import React from 'react'
import { Menu,Dropdown } from 'semantic-ui-react'
import Site_Logo from '../../media/Images/logo.PNG';
import verticalMenu from '../../media/Images/verticalMenu.png';

import '../../stylesheets/Header.css';

// import 'bootstrap/dist/css/bootstrap.min.css';
// import "../../stylesheets/www/style.css"
// import "../../stylesheets/www/responsive.css"
// // import "../../stylesheets/www/fonts/font-awesome/css/font-awesome.min.css"
// // import "../../stylesheets/www/fonts/website-font/stylesheet.css"
// import "../../stylesheets/www/mislider.css"
// import "../../stylesheets/www/mislider-custom.css"
// import "../../stylesheets/www/animate.css"
// import "../../stylesheets/www/lightbox.min.css"

import { HomeOrMenu } from './HomeOrMenu';
import {AuthenticationButton} from './AuthenticationButton'
import {LogedInMenuAuth} from './LogedInMenuAuth'
import { Link } from 'react-router-dom';
// import NavigationBar from './www/NavigationBar'


export const Header = ({token, setToken, history, aboutUs, setAboutUs, setContact}) =>{
    //const [activeItem, setActiveItem] = useState('Home');

  //const handleItemClick = (e, { name }) => setActiveItem(name)

  /*const navTo = (uri) =>{
    window.location.href = window.location.origin + uri;
  }*/
  
  //console.log(activeItem)
  return (
    <>
      {/* <div id="nav-www">
          <NavigationBar/>
      </div> */}
      <>
        <div>
          <Menu id='menu_id' widths={3} size='mini' secondary={true} inverted ={true} floated style={{object_position:"center"}}>
            
            <HomeOrMenu setAboutUs={setAboutUs} setContact={setContact}/>
            <AuthenticationButton token ={token} setToken={setToken}/>
          </Menu>
        </div>
      </>
      <>
        <LogedInMenuAuth token ={token}/>
      </>
    </>
    
    )
  
  }
  



