import React from "react";
import "./welcome_part.css";
import { Icon } from "semantic-ui-react";

import salsaPic from "../../media/Images/homepage_photos/salsa.jpg";
import bachataPic from "../../media/Images/homepage_photos/bachata.png";

const WelcomePart = () => {
  return (
    <>
      <main>
        <section class="hero">
          <div class="hero-text">
            <h1>Welcome to Forever Young</h1>
            <p>Your premier Latin dance school in the heart of the city!</p>
            <a href="#" class="btn btn-primary">
              Explore Classes
            </a>
          </div>
        </section>

        <section class="featured-classes">
          <h2>Featured Classes</h2>
          <div class="class-grid">
            <div class="class">
              <img src={salsaPic} alt="Salsa Beginners Class" />
              <h3>Salsa</h3>
              <p>
                Learn the basics of salsa dancing with our expert instructors.
              </p>
              <a href="#" class="btn btn-secondary">
                Learn More
              </a>
            </div>
            <div class="class">
              <img src={bachataPic} alt="Bachata Intermediate Class" />
              <h3>Bachata</h3>
              <p>
                Take your bachata skills to the next level with our intermediate
                class.
              </p>
              <a href="#" class="btn btn-secondary">
                Learn More
              </a>
            </div>
          </div>
        </section>

        <section class="about-us">
          <div class="container">
            <h2>About Us</h2>
            <p>
              Forever Young is a premier Latin dance school that offers classes
              in salsa and bachata for dancers of all levels, from beginners to
              advanced. Our expert instructors are passionate about teaching and
              helping each student achieve their dance goals.
            </p>
          </div>
        </section>
      </main>
    </>
  );
};

export default WelcomePart;
