import React, { useState, useEffect } from "react";
import { BasicTable } from "../../../SharedComponents/Table/BasicTable";
import { createColumns } from "./Columns";

const ActiveStudent = ({ activeStudents }) => {
  return (
    <>
      <BasicTable
        data={activeStudents}
        createColumns={() => createColumns()}
        isSelection={false}
      />
    </>
  );
};
export default ActiveStudent;
