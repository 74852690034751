import React, { useState, useEffect } from "react";
import { Table, Icon, Segment, Divider, Button } from "semantic-ui-react";
import { DateTimePickerComponent } from "@syncfusion/ej2-react-calendars";

import reportsImage from "../../media/Images/reports.jpg";
import Chart from "./Chart";
import $ from "jquery";
import styles from "./SubscriptionCategoryReport.module.scss";
import { de } from "date-fns/locale";

const DateFilter = ({ startDate, setStartDate, endDate, setEndDate, view }) => {
  return (
    <div id="payment">
      <Divider horizontal>
        <h4>
          <Icon name="filter" />
          Filter
        </h4>
      </Divider>
      <Table definition>
        <Table.Body>
          <Table.Row>
            <Table.Cell width={2}>Start Date</Table.Cell>
            <Table.Cell>
              <DateTimePickerComponent
                CssClass="customClass"
                id="StartTime"
                className="e-field"
                format="dd/MM/yy"
                value={startDate}
                onChange={(event) => setStartDate(event.target.value)}
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell width={2}>End Date</Table.Cell>
            <Table.Cell>
              <DateTimePickerComponent
                CssClass="customClass"
                id="EndTime"
                className="e-field"
                format="dd/MM/yy"
                value={endDate}
                onChange={(event) => setEndDate(event.target.value)}
              />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <Button
        id="submit_view_subs_payments"
        floated="right"
        size="mini"
        color="red"
        onClick={view}
      >
        {" "}
        View Subscriptions{" "}
      </Button>

      <Divider horizontal />
    </div>
  );
};

export default DateFilter;
