import React, { Component } from 'react';
import { Header, Image, Button, Divider, Segment, Form} from 'semantic-ui-react'
import {BasicTable} from './BasicTable';
import inst_icon from '../../media/Images/admin_icon.png';
import '../../stylesheets/admin.css'
import $ from 'jquery';
import track from '../track';


class Admin extends Component {
  constructor(props){
    super(...arguments);
    this.state = {
      admins: [],
      
      UserName: '',
      FirstName: '',
      LastName: '',
      Password: '',
      PasswordRe: '',
      ProfilePicture: null,
      Email: '',
      Address: '',
      Phone: '',
      salaryHour:0,

      values:['']
    }
  }

  componentDidMount(){
    document.getElementById("addAdmin").hidden=true;
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/admins`, //TODO: update request URL
        type: "GET",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        success: (result) => {
          this.setState({ admins: result.admins })
          return;
        },
        error: (error) => {
          alert('Unable to load admins. Please try your request again')
          return;
        }
      });
    }
    secondFunction();
  }
  
  new_admin = ()=>{
    if (document.getElementById("addAdmin").hidden){
      document.getElementById("addAdmin").hidden=false;
      document.getElementById("addAdmin").scrollIntoView();
    }else{
      document.getElementById("addAdmin").hidden=true;
      document.getElementById("tableAdmin").scrollIntoView();
    } 
  }

  handleUserName = (event) =>{
    this.setState({UserName:event.target.value})
  }

  handleFirstName = (event) =>{
    this.setState({FirstName:event.target.value})
  }

  handleLastName = (event) =>{
    this.setState({LastName:event.target.value})
  }

  handleAddress = (event) =>{
    this.setState({Address:event.target.value})
  }

  handleEmail = (event) =>{
    this.setState({Email:event.target.value})
  }

  handlePhone = (event) =>{
    this.setState({Phone:event.target.value})
  }

  handlePassword = (event) =>{
    this.setState({Password:event.target.value})
  }

  handlePasswordRe = (event) =>{
    this.setState({PasswordRe:event.target.value})
  }

  handleProfilePicture = (event)=>{
    this.setState({ProfilePicture: event.target.files[0]})
  }

  handleSalary = (event) => {
    this.setState({
      salaryHour:event.target.value
    })
  }
  

  
  addAdmin  = (event) =>{
    event.preventDefault();
    let x = 0;
    if(this.state.FirstName !== ''){x = x+1;}
    if(this.state.LastName !== ''){x = x+1;}
    if(this.state.UserName === '' ){
      alert("Please enter a unique Username to be able to sign up")
    } else if(x < 1){
      alert("Please enter your name to be able to sign up")
    }
    else if(this.state.Email === '' ){
      alert("Please enter your E-mail to be able to sign up")
    }
    else if(this.state.Phone === '' ){
      alert("Please enter your phone number to be able to sign up")
    } 
    else if(this.state.Password === '' || this.state.PasswordRe === '' || this.state.Password !== this.state.PasswordRe ){
      alert("Please check your password to be able to sign up")
    } else{
      const secondFunction = async () => {
        const result = await this.props.refreshToken()
        // do something else here after firstFunction completes
        $.ajax({
          url: `/api/admins`, //TODO: update request URL
          type: "POST",
          beforeSend: function (xhr) {   //Include the bearer token in header
            xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
          },
          dataType: 'json',
          contentType: 'application/json',
          data: JSON.stringify({
            username: this.state.UserName,
            first_name: this.state.FirstName,
            last_name: this.state.LastName,
            password: this.state.Password,
            re_password: this.state.PasswordRe,
            address: this.state.Address,
            email: this.state.Email,
            phone: this.state.Phone,
            salary_hour: this.state.salaryHour
          }),
          xhrFields: {
            withCredentials: true
          },
          crossDomain: true,
          success: (result) => {
            if (this.state.ProfilePicture !== null){
              var data1 = new FormData();
              data1.append("file", this.state.ProfilePicture)
              data1.append("filename", this.state.UserName)
              
              $.ajax({
                url: `/uploads/${result.admin.id}/admins`, //TODO: update request URL
                type: "POST",
                beforeSend: function (xhr) {   //Include the bearer token in header
                  xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
                },
                enctype: 'multipart/form-data',
                data: data1,
                processData: false,
                contentType: false,
                cache: false,
                timeout: 800000,
              
                xhrFields: {
                  withCredentials: true
                },
                crossDomain: true,
                success: (result) => {
                  document.getElementById("addAdmin").hidden=true;
                  track(`Added new admin, username = ${this.state.UserName} with picture`,
                    'Administrator', 'Add')
                  $.ajax({
                    url: `/api/admins`, //TODO: update request URL
                    type: "GET",
                    beforeSend: function (xhr) {   //Include the bearer token in header
                      xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
                    },
                    success: (result) => {
                      this.setState({ admins: result.admins });
                      document.getElementById("tableAdmin").scrollIntoView();
                      return;
                    },
                    error: (error) => {
                      alert('Unable to load admins. Please try your request again')
                      return;
                    }
                  })
                  return;
                },
                error: (error) => {
                  alert('Unable to add the profile picture. Please try your request again')
                  return;
                }
              })
            } else{
              document.getElementById("addAdmin").hidden=true;
              track(`Added new admin, username = ${this.state.UserName} without picture`,
                    'Administrator', 'Add')
              $.ajax({
                url: `/api/admins`, //TODO: update request URL
                type: "GET",
                beforeSend: function (xhr) {   //Include the bearer token in header
                  xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
                },
                success: (result) => {
                  this.setState({ admins: result.admins });
                  document.getElementById("tableAdmin").scrollIntoView();
                  return;
                },
                error: (error) => {
                  alert('Unable to load admins. Please try your request again')
                  return;
                }
              })
              return;
            }
            return;
          },
          error: (error) => {
            alert(`Unable to add admin - ${typeof error?.responseJSON?.message ===typeof "yes" ? error?.responseJSON?.message : "You have been Idol for a while, please refresh page"}`)
            return;
          }
        });
      }
      secondFunction();
    }
  }
 


  render() {
    
    return <div>
              <div id='headerPartAdmin'>
                <Header id='HeaderAdmin' as='h2' block={true} value={this.state.room}>
                  <Image size='large' circular src={inst_icon} /> Admins
                </Header>
              </div>
              <Divider horizontal/>
              
              <div >
                <Button id='admin_button1' size='mini' color='red' onClick={this.new_admin}> Add Admin </Button>
              </div>
              <Divider horizontal/>

              <div id="tableAdmin">
                  <BasicTable key ={this.state.admins}  data = {this.state.admins} />
              </div>
              <Divider horizontal/>
              

              <div id='addFormAdmin'>
                <Segment id="addAdmin" inverted>
                  <Form id='formAdmin' inverted>
                    <Form.Group>
                      <Form.Field className='fullWidthFormAdmin'>
                        <label className="labelAdmin">User Name</label>
                        <input className='input' placeholder='User Name' onChange={this.handleUserName}/>
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field className='formHalfWidthAdmin'>
                        <label className="labelAdmin">First Name</label>
                        <input className='input' placeholder='First Name' onChange={this.handleFirstName}/>
                      </Form.Field>
                      <Form.Field className='formHalfWidthAdmin'>
                        <label className="labelAdmin">Last Name</label>
                        <input className='input' placeholder='Last Name' onChange={this.handleLastName}/>
                      </Form.Field>
                    </Form.Group>

                    <Form.Group>
                      <Form.Field className='formHalfWidthAdmin'>
                        <label className="labelAdmin">Password</label>
                        <input className='input' type='password' placeholder='Password' onChange={this.handlePassword}/>
                      </Form.Field>
                      <Form.Field className='formHalfWidthAdmin'>
                        <label className="labelAdmin">Password Re-enter</label>
                        <input className='input' type='password' placeholder='Password Re-enter' onChange={this.handlePasswordRe}/>
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field className='fullWidthFormAdmin'>
                        <label className="labelAdmin">Profile Picture</label>
                        <input className='input' type='file' placeholder="Choose Admin's Profile Picture" accept="image/*" onChange={this.handleProfilePicture}/>
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field className='fullWidthFormAdmin'>
                        <label className="labelAdmin">Address</label>
                        <input className='input' placeholder='Address' onChange={this.handleAddress}/>
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field className='formHalfWidthAdmin'>
                        <label className="labelAdmin">E-Mail</label>
                        <input className='input' placeholder='E-Mail' onChange={this.handleEmail}/>
                      </Form.Field>
                      <Form.Field className='formHalfWidthAdmin'>
                        <label className="labelAdmin">Phone</label>
                        <input className='input' placeholder='Phone' onChange={this.handlePhone}/>
                      </Form.Field>
                    </Form.Group>

                    <Form.Group>
                      <Form.Field className='fullWidthFormAdmin'>
                        <label className="labelAdmin">Salary (EGP/Month)</label>
                        <input className='input' type='number' placeholder={'Salary (EGP/Month)'}
                         onChange={this.handleSalary}/>
                      </Form.Field>
                    </Form.Group>
                                       
                    <Button id='submitAdmin' floated='right' type='submit' onClick={this.addAdmin}>Submit</Button>
                  </Form>
                </Segment>
              </div>

              
           </div>
  }
  
}


export default Admin;
