import React, { Component } from 'react';
import { Header, Image, Button, Divider} from 'semantic-ui-react'
import {BasicTable} from './BasicTable';
import stud_icon from '../../../media/Images/student.png';
import '../../../stylesheets/attendanceStudentRemove.css'
import $ from 'jquery';
import track from '../../track';

class MainStudValidSubRemoveAbsent extends Component {
  constructor(props){
    super(...arguments);
    this.state = {
      attendanceId:0,
      students: []
    }
    this.setStudentToRemove = this.setStudentToRemove.bind(this)
  }

    setStudentToRemove  = (stud) =>{
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/attendances/${this.props.attendanceId}/students`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          type:'delete',
          student: stud,
          is_absent: "yes"
        }),
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
          //window.location.reload();
          track(`removed students ${this.state.studentsToAdd}from the attendance of id = ${this.props.attendanceId} `,
                    'Attendance.students', 'Remove');
                  
          
          this.props.reloadAttendance();
          return;
        },
        error: (error) => {
          alert('Unable to Remove students from the attendance. Please try your request again')
          return;
        }
      })
    }
    secondFunction();
  }

  componentDidMount(){
    this.setState({
      students: this.props.students,
      attendanceId: this.props.attendanceId
    })
  }

  
 

  render() { 
    return <div>
                <div id='headerPartAttendanceStudRemove'>
                  <Header id='HeaderAttendanceStudRemove' as='h2' block={true} >
                    <Image size='large' circular src={stud_icon} /> Absent Students 
                  </Header>
                </div>
                <Divider horizontal/>

                <div id='tableStudAttRemove'>
                    <BasicTable key ={this.props.students}  data = {this.props.students} setStudentToRemove={this.setStudentToRemove} attendanceId={this.props.attendanceId} history={this.props.history}/>
                    <Divider horizontal/>
                </div>
                <Divider horizontal/>
           </div>
  }
  
}


export default MainStudValidSubRemoveAbsent;
