import React, { Component } from 'react';
import { Header, Image, Button, Form, Segment, Divider, Icon} from 'semantic-ui-react'
import {BasicTable} from './BasicTable';
import stud_icon from '../../../media/Images/student.png';
import '../../../stylesheets/instructorShare.css'

class StudentShareToInstructor extends Component {
  constructor(props){
    super(...arguments);
    this.state = {
    }
  }

  componentDidMount(){
  }
  
  render() {
    
    return <div>
              <Divider horizontal>
                  <h4>
                    <Icon name='payment' />
                    Students
                  </h4>
                </Divider>
              <Divider horizontal/>


              <div id = 'tableInstructorShare'>
                <BasicTable key ={this.props.students}  data = {this.props.students} />
              </div>
              <Divider horizontal/>

           </div>
  }
  
}


export default StudentShareToInstructor;
