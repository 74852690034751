import React from "react";
import { Slide } from "react-slideshow-image";
import "../../stylesheets/promo_part.css";
import "react-slideshow-image/dist/styles.css";
import { Icon } from "semantic-ui-react";

// import Site_Promo from '../../media/videos/Site_Promo.mp4';
// import SiteCapture from '../../media/Images/Capture.PNG';
import pic1 from "../../media/Images/promo_photos/pic1.webp";
import pic2 from "../../media/Images/promo_photos/pic2.webp";
import pic3 from "../../media/Images/promo_photos/pic3.webp";
import pic4 from "../../media/Images/promo_photos/pic4.webp";
import pic5 from "../../media/Images/promo_photos/pic5.webp";

// const slideImages = [
//   'images/slide_2.jpg',
//   'images/slide_3.jpg',
//   'images/slide_4.jpg'
// ];

const PromoPart = () => {
  return (
    <>
      <div id="slide">
        <Slide
          pauseOnHover={true}
          canSwipe={true}
          indicators={false}
          arrows={true}
        >
          {/*easing="linear" canSwipe={true} indicators={true} arrows={false}*/}
          {/* <div className="each-slide">
            <video className="fullscreen-bg__video" loop autoPlay muted>
              <source src={Site_Promo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div> */}
          {/* <div className="each-slide">
            <img className="fullscreen-bg__video" src={SiteCapture} alt="Site Promo Cannot be displayed" />
          </div> */}
          <div className="each-slide">
            <img
              className="fullscreen-bg__video"
              src={pic1}
              alt="Site Promo Cannot be displayed"
            />
          </div>
          <div className="each-slide">
            <img
              className="fullscreen-bg__video"
              src={pic2}
              alt="Site Promo Cannot be displayed"
            />
          </div>
          <div className="each-slide">
            <img
              className="fullscreen-bg__video"
              src={pic3}
              alt="Site Promo Cannot be displayed"
            />
          </div>
          <div className="each-slide">
            <img
              className="fullscreen-bg__video"
              src={pic4}
              alt="Site Promo Cannot be displayed"
            />
          </div>
          <div className="each-slide">
            <img
              className="fullscreen-bg__video"
              src={pic5}
              alt="Site Promo Cannot be displayed"
            />
          </div>
        </Slide>
      </div>
    </>
  );
};

export default PromoPart;
