import React, { useState } from "react";
import { Dropdown, Menu, Image } from "semantic-ui-react";
import "../../stylesheets/Header.css";
import $ from "jquery";
import no_profile_picture from "../../media/Images/no_profile_picture.png";
import { Link } from "react-router-dom";

export const PartnerInstructorMenu = (props) => {
  //const [activeItem, setActiveItem] = useState('Home');
  const [firstName, setFirstName] = useState("");
  const [imageURL, setImageURL] = useState(no_profile_picture);

  //const handleItemClick = (e, { name }) => setActiveItem(name)

  $.ajax({
    url: `/api/users/${props.user_id}`, //TODO: update request URL
    type: "GET",
    beforeSend: function (xhr) {
      //Include the bearer token in header
      xhr.setRequestHeader(
        "Authorization",
        "Bearer " + sessionStorage.getItem("access_token")
      );
    },
    beforeSend: function (xhr) {
      //Include the bearer token in header
      xhr.setRequestHeader(
        "Authorization",
        "Bearer " + sessionStorage.getItem("access_token")
      );
    },
    success: (result) => {
      setFirstName(result.user.first_name);
      return;
    },
    error: (error) => {
      alert("Unable to load username. Please try your request again");
      return;
    },
  });
  $.ajax({
    url: `/uploads/${props.user_id}/users`, //TODO: update request URL
    type: "GET",
    beforeSend: function (xhr) {
      //Include the bearer token in header
      xhr.setRequestHeader(
        "Authorization",
        "Bearer " + sessionStorage.getItem("access_token")
      );
    },
    beforeSend: function (xhr) {
      //Include the bearer token in header
      xhr.setRequestHeader(
        "Authorization",
        "Bearer " + sessionStorage.getItem("access_token")
      );
    },
    processData: false,
    contentType: false,
    cache: false,
    timeout: 800000,

    success: (result) => {
      setImageURL(`data:image/jpeg;base64,${result}`);
      return;
    },
    error: (error) => {
      setImageURL(no_profile_picture);
      return;
    },
  });

  const trigger = (
    <span>
      <Image src={imageURL} avatar />
      <span>{firstName}</span>
    </span>
  );

  return (
    <>
      <Menu id="menu_2_id" widths={6} size="mini" fluid={true} inverted={true}>
        <Menu.Item className="menu_2_item" name="Home" as={Link} to={"/"}>
          Home
          {/*<img src={Site_Logo} alt="Soul motion logo" ></img>*/}
        </Menu.Item>
        <Dropdown className="menu_2_item" item text="Schedule">
          <Dropdown.Menu>
            <Dropdown.Item
              icon="calendar check"
              text="My Schedule"
              as={Link}
              to={`/instructors/${props.user_id}/schedules`}
              //onClick={() => {navTo('/rooms')}}
            />
            <Dropdown.Item
              icon="calendar"
              text="Full Schedule"
              as={Link}
              to={"/schedules"}
              //onClick={() => {navTo('/categories')}}
            />
            {/*<Dropdown.Item icon='calendar plus' text='Requests Schedule' as={Link}
                        to={'/requests-schedule'}
                        />*/}
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown className="menu_2_item" item text="User Privileges">
          <Dropdown.Menu>
            <Dropdown.Item
              icon="edit"
              text="Partners"
              as={Link}
              to={"/partners"}
              //onClick={() => {navTo('/students')}}
            />
            <Dropdown.Item
              icon="edit"
              text="Administrators"
              as={Link}
              to={"/admins"}
              //onClick={() => {navTo('/students')}}
            />
            <Dropdown.Item
              icon="edit"
              text="Employees"
              as={Link}
              to={"/employees"}
            />
            <Dropdown.Item
              icon="edit"
              text="Instructors"
              as={Link}
              to={"/instructors"}
              //onClick={() => {navTo('/instructors')}}
            />
            <Dropdown.Item
              icon="edit"
              text="Students"
              as={Link}
              to={"/students"}
              //onClick={() => {navTo('/students')}}
            />
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown className="menu_2_item" item text="Administration">
          <Dropdown.Menu>
            <Dropdown.Item
              icon="edit"
              text="Edit Rooms"
              as={Link}
              to={"/rooms"}
              //onClick={() => {navTo('/rooms')}}
            />
            <Dropdown.Item
              icon="edit"
              text="Edit Categories"
              as={Link}
              to={"/categories"}
              //onClick={() => {navTo('/categories')}}
            />
            <Dropdown.Item
              icon="edit"
              text="Edit Dances"
              as={Link}
              to={"/dances"}
              //onClick={() => {navTo('/dances')}}
            />
            <Dropdown.Item
              icon="edit"
              text="Edit Levels"
              as={Link}
              to={"/levels"}
              //onClick={() => {navTo('/levels')}}
            />
            <Dropdown.Item
              icon="edit"
              text="Edit Packages"
              as={Link}
              to={"/packages"}
              //onClick={() => {navTo('/packages')}}
            />
            <Dropdown.Item
              icon="edit"
              text="Edit Memberships"
              as={Link}
              to={"/memberships"}
              //onClick={() => {navTo('/memberships')}}
            />
            <Dropdown.Item
              icon="edit"
              text="Edit Subscriptions"
              as={Link}
              to={"/subscriptions"}
              //onClick={() => {navTo('/subscriptions')}}
            />
            <Dropdown.Item
              icon="edit"
              text="Students Report"
              as={Link}
              to={"/students-report"}
            />
            <Dropdown.Item
              icon="edit"
              text="Subs vs. Cat."
              as={Link}
              to={"/subscriptions-catergory-report"}
            />
            <Dropdown.Item
              icon="edit"
              text="Subs Monthly"
              as={Link}
              to={"/subscriptions-monthly-report"}
            />
            <Dropdown.Item
              icon="edit"
              text="Edit Miscs"
              as={Link}
              to={"/miscs"}
              //onClick={() => {navTo('/miscs')}}
            />
            <Dropdown.Item
              icon="edit"
              text="Edit Kitchens"
              as={Link}
              to={"/kitchens"}
              //onClick={() => {navTo('/miscs')}}
            />
            <Dropdown.Item icon="edit" text="Leaves" as={Link} to={"/leaves"} />
            <Dropdown.Item icon="edit" text="Track" as={Link} to={"/tracks"} />
            <Dropdown.Item
              icon="edit"
              text="Log"
              as={Link}
              to={"/login-tracks"}
            />
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown className="menu_2_item" item text="Finance">
          <Dropdown.Menu>
            <Dropdown.Item
              icon="payment"
              text="Payslip"
              as={Link}
              to={`/users/${props.user_id}/payslips`}
              //onClick={() => {navTo('/rooms')}}
            />

            <Dropdown.Item
              icon="payment"
              text="Daily Payments"
              as={Link}
              to={"/daily-payments"}
              //onClick={() => {navTo('/rooms')}}
            />
            <Dropdown.Item
              icon="money bill alternate"
              text="Monthly Closing"
              as={Link}
              to={"/closings"}
              //onClick={() => {navTo('/categories')}}
            />
          </Dropdown.Menu>
        </Dropdown>

        <Menu.Item
          className="menu_2_item"
          name="users"
          //active={activeItem === 'users'}
          as={Link}
          to={`/users/${props.user_id}`}
          //onClick={() => {handleItemClick()}}
        >
          <div>
            <Image src={imageURL} avatar />
            <span>{firstName}</span>
          </div>
        </Menu.Item>
      </Menu>

      <Dropdown
        id="menu_2_id_phone_part_inst"
        text="Menu"
        floating
        labeled
        button
        className="icon"
      >
        <Dropdown.Menu>
          <Dropdown.Item icon="home" text="Home" as={Link} to={`/`} />
          <Dropdown.Divider />
          <Dropdown.Item
            icon="calendar check"
            text="My Schedule"
            as={Link}
            to={`/instructors/${props.user_id}/schedules`}
          />
          <Dropdown.Item
            icon="calendar"
            text="Full Schedule"
            as={Link}
            to={"/schedules"}
          />
          {/*<Dropdown.Item icon='calendar plus' text='Requests' as={Link}
                        to={'/requests-schedule'}
                    />*/}
          <Dropdown.Divider />
          <Dropdown.Item
            icon="user"
            text="Partners"
            as={Link}
            to={"/partners"}
          />
          <Dropdown.Item icon="user" text="Admins" as={Link} to={"/admins"} />
          <Dropdown.Item
            icon="user"
            text="Employees"
            as={Link}
            to={"/employees"}
          />
          <Dropdown.Item
            icon="user"
            text="Instructors"
            as={Link}
            to={"/instructors"}
          />
          <Dropdown.Item
            icon="user"
            text="Students"
            as={Link}
            to={"/students"}
          />
          <Dropdown.Divider />
          <Dropdown.Item icon="setting" text="Rooms" as={Link} to={"/rooms"} />
          <Dropdown.Item
            icon="setting"
            text="Categories"
            as={Link}
            to={"/categories"}
          />
          <Dropdown.Item
            icon="setting"
            text="Dances"
            as={Link}
            to={"/dances"}
          />
          <Dropdown.Item
            icon="setting"
            text="Levels"
            as={Link}
            to={"/levels"}
          />
          <Dropdown.Item
            icon="setting"
            text="Packages"
            as={Link}
            to={"/packages"}
          />
          <Dropdown.Item
            icon="setting"
            text="Memberships"
            as={Link}
            to={"/memberships"}
          />
          <Dropdown.Item
            icon="setting"
            text="Subscriptions"
            as={Link}
            to={"/subscriptions"}
          />
          <Dropdown.Item
            icon="edit"
            text="Students Report"
            as={Link}
            to={"/students-report"}
          />
          <Dropdown.Item
            icon="edit"
            text="Subs vs. Cat."
            as={Link}
            to={"/subscriptions-catergory-report"}
          />
          <Dropdown.Item
            icon="edit"
            text="Subs Monthly"
            as={Link}
            to={"/subscriptions-monthly-report"}
          />
          <Dropdown.Item icon="setting" text="Miscs" as={Link} to={"/miscs"} />
          <Dropdown.Item
            icon="setting"
            text="Kitchens"
            as={Link}
            to={"/kitchens"}
          />
          <Dropdown.Item
            icon="setting"
            text="Leaves"
            as={Link}
            to={"/leaves"}
          />
          <Dropdown.Item icon="setting" text="Track" as={Link} to={"/tracks"} />
          <Dropdown.Item
            icon="setting"
            text="Log"
            as={Link}
            to={"/login-tracks"}
          />

          <Dropdown.Divider />
          <Dropdown.Item
            icon="money bill alternate outline"
            text="Payslip"
            as={Link}
            to={`/users/${props.user_id}/payslips`}
          />

          <Dropdown.Item
            icon="money bill alternate outline"
            text="Daily Payments"
            as={Link}
            to={"/daily-payments"}
          />
          <Dropdown.Item
            icon="money bill alternate outline"
            text="Monthly Closing"
            as={Link}
            to={"/closings"}
          />
          <Dropdown.Divider />
          <Dropdown.Item
            icon="user"
            content={`${firstName}'s profile'`}
            as={Link}
            to={`/users/${props.user_id}`}
          />
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};
/*
<div>
    <Image src={imageURL} avatar />
    <span>{firstName}</span>
</div>
*/

/*
<Image src={imageURL} size='tiny' verticalAlign='middle' />{' '}
<span>{firstName}</span>
*/
