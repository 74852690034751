import React from "react";
import { useMediaQuery } from "react-responsive";
import { TableDesktop } from "../TableDesktop/TableDesktop";
import { TableMobile } from "../TableMobile/TableMobile";

export const BasicTable = (props) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isTabletOrMobile = useMediaQuery({
    query: "(max-width: 1224px)",
  });

  return (
    <>
      {isDesktopOrLaptop && <TableDesktop {...props} />}
      {isTabletOrMobile && <TableMobile {...props} />}
    </>
  );
};
